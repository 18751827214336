// @generated by protoc-gen-es v1.10.0 with parameter "target=ts,import_extension=.ts"
// @generated from file retriver-struct.proto (package retrica, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";
import { ActivityType, ChannelContentType, ChannelType, ContentType, ErrorCode, FeedItemType, FriendType, HashTagType, NotificationType, ProductPlan, ProductType, ResourceType, ThemeType, Toggle } from "./retriver-enum_pb.ts";
import { ImageProcess } from "./retriver-image-process_pb.ts";

/**
 * @generated from message retrica.User
 */
export class User extends Message<User> {
  /**
   * @generated from field: string user_id = 1;
   */
  userId = "";

  /**
   * @generated from field: string username = 2;
   */
  username = "";

  /**
   * @generated from field: string profile_url = 3;
   */
  profileUrl = "";

  /**
   * @generated from field: string fullname = 4;
   */
  fullname = "";

  /**
   * @generated from field: string location = 5;
   */
  location = "";

  /**
   * YYYY-MM-DD
   *
   * @generated from field: string birth = 6;
   */
  birth = "";

  /**
   * M OR F
   *
   * @generated from field: string gender = 7;
   */
  gender = "";

  /**
   * @generated from field: retrica.ContentType profile_type = 8;
   */
  profileType = ContentType.COT_NONE;

  /**
   * @generated from field: string location_code = 9;
   */
  locationCode = "";

  /**
   * @generated from field: retrica.ThemeType theme_type = 10;
   */
  themeType = ThemeType.TT_NONE;

  /**
   * @generated from field: string snapchat_name = 11;
   */
  snapchatName = "";

  /**
   * @generated from field: string instagram_name = 12;
   */
  instagramName = "";

  /**
   * @generated from field: string musically_name = 13;
   */
  musicallyName = "";

  /**
   * @generated from field: string kik_name = 14;
   */
  kikName = "";

  /**
   * @generated from field: string bio = 15;
   */
  bio = "";

  constructor(data?: PartialMessage<User>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.User";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "profile_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "fullname", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "location", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "birth", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "gender", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "profile_type", kind: "enum", T: proto3.getEnumType(ContentType) },
    { no: 9, name: "location_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "theme_type", kind: "enum", T: proto3.getEnumType(ThemeType) },
    { no: 11, name: "snapchat_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "instagram_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "musically_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "kik_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 15, name: "bio", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): User {
    return new User().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): User {
    return new User().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): User {
    return new User().fromJsonString(jsonString, options);
  }

  static equals(a: User | PlainMessage<User> | undefined, b: User | PlainMessage<User> | undefined): boolean {
    return proto3.util.equals(User, a, b);
  }
}

/**
 * @generated from message retrica.Friend
 */
export class Friend extends Message<Friend> {
  /**
   * @generated from field: retrica.User user = 1;
   */
  user?: User;

  /**
   * @generated from field: retrica.FriendType type = 2;
   */
  type = FriendType.FT_NONE;

  /**
   * @generated from field: string contact_name = 3;
   */
  contactName = "";

  /**
   * @generated from field: int64 updated_at_nano = 5;
   */
  updatedAtNano = protoInt64.zero;

  /**
   * DEPRECATED
   *
   * @generated from field: string DEPRECATED_updated_at = 4;
   */
  DEPRECATEDUpdatedAt = "";

  constructor(data?: PartialMessage<Friend>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.Friend";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user", kind: "message", T: User },
    { no: 2, name: "type", kind: "enum", T: proto3.getEnumType(FriendType) },
    { no: 3, name: "contact_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "updated_at_nano", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "DEPRECATED_updated_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Friend {
    return new Friend().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Friend {
    return new Friend().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Friend {
    return new Friend().fromJsonString(jsonString, options);
  }

  static equals(a: Friend | PlainMessage<Friend> | undefined, b: Friend | PlainMessage<Friend> | undefined): boolean {
    return proto3.util.equals(Friend, a, b);
  }
}

/**
 * @generated from message retrica.Account
 */
export class Account extends Message<Account> {
  /**
   * @generated from field: string access_token = 1;
   */
  accessToken = "";

  /**
   * @generated from field: string user_id = 2;
   */
  userId = "";

  /**
   * @generated from field: string username = 3;
   */
  username = "";

  /**
   * @generated from field: string email = 4;
   */
  email = "";

  /**
   * @generated from field: string phone_number = 5;
   */
  phoneNumber = "";

  /**
   * @generated from field: string profile_url = 6;
   */
  profileUrl = "";

  /**
   * @generated from field: bool push = 7;
   */
  push = false;

  /**
   * @generated from field: string facebook_id = 8;
   */
  facebookId = "";

  /**
   * @generated from field: string fullname = 9;
   */
  fullname = "";

  /**
   * @generated from field: string vkontakte_id = 10;
   */
  vkontakteId = "";

  /**
   * @generated from field: string appleId = 25;
   */
  appleId = "";

  /**
   * @generated from field: string huawei_id = 26;
   */
  huaweiId = "";

  /**
   * @generated from field: string location = 11;
   */
  location = "";

  /**
   * @generated from field: retrica.Toggle push_toggle = 12;
   */
  pushToggle = Toggle.TG_NONE;

  /**
   * @generated from field: retrica.Toggle location_toggle = 13;
   */
  locationToggle = Toggle.TG_NONE;

  /**
   * YYYY-MM-DD
   *
   * @generated from field: string birth = 14;
   */
  birth = "";

  /**
   * M OR F
   *
   * @generated from field: string gender = 15;
   */
  gender = "";

  /**
   * @generated from field: retrica.ContentType profile_type = 17;
   */
  profileType = ContentType.COT_NONE;

  /**
   * @generated from field: string location_code = 18;
   */
  locationCode = "";

  /**
   * @generated from field: retrica.ThemeType theme_type = 19;
   */
  themeType = ThemeType.TT_NONE;

  /**
   * @generated from field: string snapchat_name = 20;
   */
  snapchatName = "";

  /**
   * @generated from field: string instagram_name = 21;
   */
  instagramName = "";

  /**
   * @generated from field: string musically_name = 22;
   */
  musicallyName = "";

  /**
   * @generated from field: string kik_name = 23;
   */
  kikName = "";

  /**
   * @generated from field: string bio = 24;
   */
  bio = "";

  /**
   * @generated from field: repeated string roles = 27;
   */
  roles: string[] = [];

  /**
   * DEPRECATED
   *
   * @generated from field: repeated retrica.ProfileContent DEPRECATED_contents = 16;
   */
  DEPRECATEDContents: ProfileContent[] = [];

  constructor(data?: PartialMessage<Account>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.Account";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "access_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "phone_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "profile_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "push", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: "facebook_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "fullname", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "vkontakte_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 25, name: "appleId", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 26, name: "huawei_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "location", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "push_toggle", kind: "enum", T: proto3.getEnumType(Toggle) },
    { no: 13, name: "location_toggle", kind: "enum", T: proto3.getEnumType(Toggle) },
    { no: 14, name: "birth", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 15, name: "gender", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 17, name: "profile_type", kind: "enum", T: proto3.getEnumType(ContentType) },
    { no: 18, name: "location_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 19, name: "theme_type", kind: "enum", T: proto3.getEnumType(ThemeType) },
    { no: 20, name: "snapchat_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 21, name: "instagram_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 22, name: "musically_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 23, name: "kik_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 24, name: "bio", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 27, name: "roles", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 16, name: "DEPRECATED_contents", kind: "message", T: ProfileContent, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Account {
    return new Account().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Account {
    return new Account().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Account {
    return new Account().fromJsonString(jsonString, options);
  }

  static equals(a: Account | PlainMessage<Account> | undefined, b: Account | PlainMessage<Account> | undefined): boolean {
    return proto3.util.equals(Account, a, b);
  }
}

/**
 * @generated from message retrica.Content
 */
export class Content extends Message<Content> {
  /**
   * ContentType Request, Response Type (Request, Response)
   *
   * @generated from field: retrica.ContentType type = 1;
   */
  type = ContentType.COT_NONE;

  /**
   * ThumbNail ContentType (Request, Response)
   *
   * @generated from field: retrica.ContentType thumb_type = 15;
   */
  thumbType = ContentType.COT_NONE;

  /**
   * Photo/Video 원본 (Request)
   *
   * @generated from field: bytes origin = 2;
   */
  origin = new Uint8Array(0);

  /**
   * Video/Photo Thumb (Request)
   *
   * @generated from field: bytes thumb = 3;
   */
  thumb = new Uint8Array(0);

  /**
   * Resumable 원본 URL (Request)
   *
   * @generated from field: string resumable_origin_url = 16;
   */
  resumableOriginUrl = "";

  /**
   * Resumable Thumb URL (Request)
   *
   * @generated from field: string resumable_thumb_url = 17;
   */
  resumableThumbUrl = "";

  /**
   * 원본 가로 사이즈 (Request, Response)
   *
   * @generated from field: int32 width = 4;
   */
  width = 0;

  /**
   * 원본 세로 사이즈 (Request, Response)
   *
   * @generated from field: int32 height = 5;
   */
  height = 0;

  /**
   * 원본 URL  (Response)
   *
   * @generated from field: string origin_url = 6;
   */
  originUrl = "";

  /**
   * 원본 URL  Headers (Response)
   *
   * @generated from field: map<string, string> origin_url_headers = 7;
   */
  originUrlHeaders: { [key: string]: string } = {};

  /**
   * Thumbnail URL (Response)
   *
   * @generated from field: string thumb_url = 8;
   */
  thumbUrl = "";

  /**
   * Thumbnail URL Headers (Response)
   *
   * @generated from field: map<string, string> thumb_url_headers = 9;
   */
  thumbUrlHeaders: { [key: string]: string } = {};

  /**
   * Upload Timestamp In nanos(Response)
   *
   * @generated from field: int64 upload_time = 10;
   */
  uploadTime = protoInt64.zero;

  /**
   * 촬영시간
   *
   * @generated from field: int64 shooting_time = 11;
   */
  shootingTime = protoInt64.zero;

  /**
   * Filter name
   *
   * @generated from field: string filter_name = 12;
   */
  filterName = "";

  /**
   * Set if location is on.
   *
   * @generated from field: double latitude = 13;
   */
  latitude = 0;

  /**
   * Set if location is on.
   *
   * @generated from field: double longitude = 14;
   */
  longitude = 0;

  /**
   * @generated from field: double filter_intensity = 18;
   */
  filterIntensity = 0;

  /**
   * @generated from field: double altitude = 19;
   */
  altitude = 0;

  constructor(data?: PartialMessage<Content>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.Content";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "type", kind: "enum", T: proto3.getEnumType(ContentType) },
    { no: 15, name: "thumb_type", kind: "enum", T: proto3.getEnumType(ContentType) },
    { no: 2, name: "origin", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
    { no: 3, name: "thumb", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
    { no: 16, name: "resumable_origin_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 17, name: "resumable_thumb_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "width", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "height", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "origin_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "origin_url_headers", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
    { no: 8, name: "thumb_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "thumb_url_headers", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
    { no: 10, name: "upload_time", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 11, name: "shooting_time", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 12, name: "filter_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "latitude", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 14, name: "longitude", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 18, name: "filter_intensity", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 19, name: "altitude", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Content {
    return new Content().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Content {
    return new Content().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Content {
    return new Content().fromJsonString(jsonString, options);
  }

  static equals(a: Content | PlainMessage<Content> | undefined, b: Content | PlainMessage<Content> | undefined): boolean {
    return proto3.util.equals(Content, a, b);
  }
}

/**
 * @generated from message retrica.UserProperties
 */
export class UserProperties extends Message<UserProperties> {
  /**
   * @generated from field: string DEPRECATED_access_token = 1;
   */
  DEPRECATEDAccessToken = "";

  /**
   * @generated from field: string DEPRECATED_mcc = 6;
   */
  DEPRECATEDMcc = "";

  /**
   * @generated from field: string DEPRECATED_mnc = 7;
   */
  DEPRECATEDMnc = "";

  /**
   * @generated from field: string DEPRECATED_idfa = 9;
   */
  DEPRECATEDIdfa = "";

  /**
   * @generated from field: int32 DEPRECATED_app_build = 11;
   */
  DEPRECATEDAppBuild = 0;

  /**
   * @generated from field: bool DEPRECATED_permission_address_book = 17;
   */
  DEPRECATEDPermissionAddressBook = false;

  /**
   * @generated from field: bool DEPRECATED_permission_location = 18;
   */
  DEPRECATEDPermissionLocation = false;

  /**
   * @generated from field: bool DEPRECATED_permission_push_notification = 19;
   */
  DEPRECATEDPermissionPushNotification = false;

  /**
   * The platform of the device. Examples: android, ios
   *
   * @generated from field: string platform = 2;
   */
  platform = "";

  /**
   * Contains the OS version of the platform.
   *
   * @generated from field: string os_version = 3;
   */
  osVersion = "";

  /**
   * Retrica App Version. e.g. "3.0.0"
   *
   * @generated from field: string app_version = 4;
   */
  appVersion = "";

  /**
   * Language of the device. Use alpha-2/ISO 639-1 codes. e.g. en,ko
   * http://en.wikipedia.org/wiki/List_of_ISO_639-1_codes
   *
   * @generated from field: string language = 5;
   */
  language = "";

  /**
   * Unique Device Id generated by device.
   *
   * @generated from field: string device_id = 8;
   */
  deviceId = "";

  /**
   * Internet connection type. Examples: 2g, edge, 3g, lte, wifi
   *
   * @generated from field: string network = 10;
   */
  network = "";

  /**
   * share and shutter
   *
   * @generated from field: int32 shutter_count_total = 12;
   */
  shutterCountTotal = 0;

  /**
   * @generated from field: int32 shutter_count_front = 13;
   */
  shutterCountFront = 0;

  /**
   * @generated from field: int32 shutter_count_rear = 14;
   */
  shutterCountRear = 0;

  /**
   * @generated from field: int32 toss_share_count = 15;
   */
  tossShareCount = 0;

  /**
   * instagram, whatsapp ..등
   *
   * @generated from field: int32 default_share_count = 16;
   */
  defaultShareCount = 0;

  /**
   * filter and album
   *
   * @generated from field: int32 favorite_filter_count = 20;
   */
  favoriteFilterCount = 0;

  /**
   * @generated from field: int32 filter_activated_count = 21;
   */
  filterActivatedCount = 0;

  /**
   * @generated from field: int32 retrica_album_count = 22;
   */
  retricaAlbumCount = 0;

  /**
   * @generated from field: int32 toss_friend_count = 23;
   */
  tossFriendCount = 0;

  /**
   * @generated from field: int32 toss_channel_count = 24;
   */
  tossChannelCount = 0;

  /**
   * @generated from field: int32 toss_comment_count = 25;
   */
  tossCommentCount = 0;

  /**
   * @generated from field: map<string, int32> int_properties = 26;
   */
  intProperties: { [key: string]: number } = {};

  /**
   * @generated from field: map<string, string> string_properties = 27;
   */
  stringProperties: { [key: string]: string } = {};

  constructor(data?: PartialMessage<UserProperties>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.UserProperties";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "DEPRECATED_access_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "DEPRECATED_mcc", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "DEPRECATED_mnc", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "DEPRECATED_idfa", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "DEPRECATED_app_build", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 17, name: "DEPRECATED_permission_address_book", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 18, name: "DEPRECATED_permission_location", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 19, name: "DEPRECATED_permission_push_notification", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "platform", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "os_version", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "app_version", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "language", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "device_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "network", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "shutter_count_total", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 13, name: "shutter_count_front", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 14, name: "shutter_count_rear", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 15, name: "toss_share_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 16, name: "default_share_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 20, name: "favorite_filter_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 21, name: "filter_activated_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 22, name: "retrica_album_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 23, name: "toss_friend_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 24, name: "toss_channel_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 25, name: "toss_comment_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 26, name: "int_properties", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 5 /* ScalarType.INT32 */} },
    { no: 27, name: "string_properties", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UserProperties {
    return new UserProperties().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UserProperties {
    return new UserProperties().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UserProperties {
    return new UserProperties().fromJsonString(jsonString, options);
  }

  static equals(a: UserProperties | PlainMessage<UserProperties> | undefined, b: UserProperties | PlainMessage<UserProperties> | undefined): boolean {
    return proto3.util.equals(UserProperties, a, b);
  }
}

/**
 * @generated from message retrica.EventLogs
 */
export class EventLogs extends Message<EventLogs> {
  /**
   * @generated from field: map<string, string> DEPRECATED_request_params = 6;
   */
  DEPRECATEDRequestParams: { [key: string]: string } = {};

  /**
   * @generated from field: repeated retrica.Event DEPRECATED_events = 2;
   */
  DEPRECATEDEvents: Event[] = [];

  /**
   * @generated from field: retrica.UserProperties user_properties = 1;
   */
  userProperties?: UserProperties;

  /**
   * @generated from field: string ip = 3;
   */
  ip = "";

  /**
   * @generated from field: string country = 4;
   */
  country = "";

  /**
   * @generated from field: string event_name = 5;
   */
  eventName = "";

  /**
   * @generated from field: map<string, string> event_params_string = 7;
   */
  eventParamsString: { [key: string]: string } = {};

  /**
   * @generated from field: int64 timestamp_nanos = 8;
   */
  timestampNanos = protoInt64.zero;

  /**
   * @generated from field: map<string, int32> event_params_int = 9;
   */
  eventParamsInt: { [key: string]: number } = {};

  constructor(data?: PartialMessage<EventLogs>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.EventLogs";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 6, name: "DEPRECATED_request_params", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
    { no: 2, name: "DEPRECATED_events", kind: "message", T: Event, repeated: true },
    { no: 1, name: "user_properties", kind: "message", T: UserProperties },
    { no: 3, name: "ip", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "country", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "event_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "event_params_string", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
    { no: 8, name: "timestamp_nanos", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 9, name: "event_params_int", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 5 /* ScalarType.INT32 */} },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EventLogs {
    return new EventLogs().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EventLogs {
    return new EventLogs().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EventLogs {
    return new EventLogs().fromJsonString(jsonString, options);
  }

  static equals(a: EventLogs | PlainMessage<EventLogs> | undefined, b: EventLogs | PlainMessage<EventLogs> | undefined): boolean {
    return proto3.util.equals(EventLogs, a, b);
  }
}

/**
 * [retrica]
 *
 * @generated from message retrica.Resource
 */
export class Resource extends Message<Resource> {
  constructor(data?: PartialMessage<Resource>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.Resource";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Resource {
    return new Resource().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Resource {
    return new Resource().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Resource {
    return new Resource().fromJsonString(jsonString, options);
  }

  static equals(a: Resource | PlainMessage<Resource> | undefined, b: Resource | PlainMessage<Resource> | undefined): boolean {
    return proto3.util.equals(Resource, a, b);
  }
}

/**
 * @generated from message retrica.Resource.Sticker
 */
export class Resource_Sticker extends Message<Resource_Sticker> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string category = 2;
   */
  category = "";

  /**
   * @generated from field: string url = 3;
   */
  url = "";

  /**
   * @generated from field: string tag = 4;
   */
  tag = "";

  /**
   * @generated from field: int32 priority = 5;
   */
  priority = 0;

  /**
   * @generated from field: int64 start_at = 6;
   */
  startAt = protoInt64.zero;

  /**
   * @generated from field: int64 expire_at = 7;
   */
  expireAt = protoInt64.zero;

  constructor(data?: PartialMessage<Resource_Sticker>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.Resource.Sticker";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "category", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "tag", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "priority", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "start_at", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 7, name: "expire_at", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Resource_Sticker {
    return new Resource_Sticker().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Resource_Sticker {
    return new Resource_Sticker().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Resource_Sticker {
    return new Resource_Sticker().fromJsonString(jsonString, options);
  }

  static equals(a: Resource_Sticker | PlainMessage<Resource_Sticker> | undefined, b: Resource_Sticker | PlainMessage<Resource_Sticker> | undefined): boolean {
    return proto3.util.equals(Resource_Sticker, a, b);
  }
}

/**
 * @generated from message retrica.Resource.Stamp
 */
export class Resource_Stamp extends Message<Resource_Stamp> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string category = 2;
   */
  category = "";

  /**
   * @generated from field: string url = 3;
   */
  url = "";

  /**
   * @generated from field: string tag = 4;
   */
  tag = "";

  /**
   * @generated from field: int32 priority = 5;
   */
  priority = 0;

  /**
   * @generated from field: int64 start_at = 6;
   */
  startAt = protoInt64.zero;

  /**
   * @generated from field: int64 expire_at = 7;
   */
  expireAt = protoInt64.zero;

  constructor(data?: PartialMessage<Resource_Stamp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.Resource.Stamp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "category", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "tag", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "priority", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "start_at", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 7, name: "expire_at", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Resource_Stamp {
    return new Resource_Stamp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Resource_Stamp {
    return new Resource_Stamp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Resource_Stamp {
    return new Resource_Stamp().fromJsonString(jsonString, options);
  }

  static equals(a: Resource_Stamp | PlainMessage<Resource_Stamp> | undefined, b: Resource_Stamp | PlainMessage<Resource_Stamp> | undefined): boolean {
    return proto3.util.equals(Resource_Stamp, a, b);
  }
}

/**
 * @generated from message retrica.Resource.Category
 */
export class Resource_Category extends Message<Resource_Category> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: retrica.ResourceType resource_type = 2;
   */
  resourceType = ResourceType.RT_NONE;

  /**
   * @generated from field: string url = 3;
   */
  url = "";

  /**
   * @generated from field: int32 grid = 4;
   */
  grid = 0;

  /**
   * @generated from field: int32 priority = 5;
   */
  priority = 0;

  constructor(data?: PartialMessage<Resource_Category>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.Resource.Category";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "resource_type", kind: "enum", T: proto3.getEnumType(ResourceType) },
    { no: 3, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "grid", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "priority", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Resource_Category {
    return new Resource_Category().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Resource_Category {
    return new Resource_Category().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Resource_Category {
    return new Resource_Category().fromJsonString(jsonString, options);
  }

  static equals(a: Resource_Category | PlainMessage<Resource_Category> | undefined, b: Resource_Category | PlainMessage<Resource_Category> | undefined): boolean {
    return proto3.util.equals(Resource_Category, a, b);
  }
}

/**
 * [store]
 *
 * @generated from message retrica.Store
 */
export class Store extends Message<Store> {
  constructor(data?: PartialMessage<Store>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.Store";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Store {
    return new Store().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Store {
    return new Store().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Store {
    return new Store().fromJsonString(jsonString, options);
  }

  static equals(a: Store | PlainMessage<Store> | undefined, b: Store | PlainMessage<Store> | undefined): boolean {
    return proto3.util.equals(Store, a, b);
  }
}

/**
 * @generated from message retrica.Store.ProductDetail
 */
export class Store_ProductDetail extends Message<Store_ProductDetail> {
  /**
   * @generated from field: string title = 1;
   */
  title = "";

  /**
   * @generated from field: string sub_title = 2;
   */
  subTitle = "";

  /**
   * @generated from field: string description = 3;
   */
  description = "";

  /**
   * @generated from field: string sub_description = 4;
   */
  subDescription = "";

  /**
   * @generated from field: string media_url = 5;
   */
  mediaUrl = "";

  /**
   * @generated from field: repeated string sub_media_urls = 6;
   */
  subMediaUrls: string[] = [];

  /**
   * 만약 filterpack type일 경우 packId
   *
   * @generated from field: string filterpack_pack_id = 7;
   */
  filterpackPackId = "";

  /**
   * @generated from field: repeated string filterpack_colors = 8;
   */
  filterpackColors: string[] = [];

  constructor(data?: PartialMessage<Store_ProductDetail>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.Store.ProductDetail";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "sub_title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "sub_description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "media_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "sub_media_urls", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 7, name: "filterpack_pack_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "filterpack_colors", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Store_ProductDetail {
    return new Store_ProductDetail().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Store_ProductDetail {
    return new Store_ProductDetail().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Store_ProductDetail {
    return new Store_ProductDetail().fromJsonString(jsonString, options);
  }

  static equals(a: Store_ProductDetail | PlainMessage<Store_ProductDetail> | undefined, b: Store_ProductDetail | PlainMessage<Store_ProductDetail> | undefined): boolean {
    return proto3.util.equals(Store_ProductDetail, a, b);
  }
}

/**
 * @generated from message retrica.Store.Product
 */
export class Store_Product extends Message<Store_Product> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: retrica.ProductType type = 2;
   */
  type = ProductType.PTT_NONE;

  /**
   * @generated from field: retrica.ProductPlan plan = 3;
   */
  plan = ProductPlan.PTP_NONE;

  /**
   * @generated from field: string url = 4;
   */
  url = "";

  /**
   * @generated from field: int64 published_at = 5;
   */
  publishedAt = protoInt64.zero;

  /**
   * @generated from field: retrica.Store.ProductDetail detail = 6;
   */
  detail?: Store_ProductDetail;

  /**
   * @generated from field: int64 created_at = 7;
   */
  createdAt = protoInt64.zero;

  /**
   * @generated from field: int64 updated_at = 8;
   */
  updatedAt = protoInt64.zero;

  constructor(data?: PartialMessage<Store_Product>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.Store.Product";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "type", kind: "enum", T: proto3.getEnumType(ProductType) },
    { no: 3, name: "plan", kind: "enum", T: proto3.getEnumType(ProductPlan) },
    { no: 4, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "published_at", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 6, name: "detail", kind: "message", T: Store_ProductDetail },
    { no: 7, name: "created_at", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 8, name: "updated_at", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Store_Product {
    return new Store_Product().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Store_Product {
    return new Store_Product().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Store_Product {
    return new Store_Product().fromJsonString(jsonString, options);
  }

  static equals(a: Store_Product | PlainMessage<Store_Product> | undefined, b: Store_Product | PlainMessage<Store_Product> | undefined): boolean {
    return proto3.util.equals(Store_Product, a, b);
  }
}

/**
 * @generated from message retrica.Store.ProductList
 */
export class Store_ProductList extends Message<Store_ProductList> {
  /**
   * @generated from field: repeated retrica.Store.Product products = 1;
   */
  products: Store_Product[] = [];

  constructor(data?: PartialMessage<Store_ProductList>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.Store.ProductList";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "products", kind: "message", T: Store_Product, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Store_ProductList {
    return new Store_ProductList().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Store_ProductList {
    return new Store_ProductList().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Store_ProductList {
    return new Store_ProductList().fromJsonString(jsonString, options);
  }

  static equals(a: Store_ProductList | PlainMessage<Store_ProductList> | undefined, b: Store_ProductList | PlainMessage<Store_ProductList> | undefined): boolean {
    return proto3.util.equals(Store_ProductList, a, b);
  }
}

/**
 * @generated from message retrica.PlayStoreResponse
 */
export class PlayStoreResponse extends Message<PlayStoreResponse> {
  /**
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * @generated from field: int64 startTimeMillis = 2;
   */
  startTimeMillis = protoInt64.zero;

  /**
   * @generated from field: int64 expiryTimeMillis = 3;
   */
  expiryTimeMillis = protoInt64.zero;

  /**
   * @generated from field: bool isValid = 4;
   */
  isValid = false;

  constructor(data?: PartialMessage<PlayStoreResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.PlayStoreResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "startTimeMillis", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "expiryTimeMillis", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "isValid", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PlayStoreResponse {
    return new PlayStoreResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PlayStoreResponse {
    return new PlayStoreResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PlayStoreResponse {
    return new PlayStoreResponse().fromJsonString(jsonString, options);
  }

  static equals(a: PlayStoreResponse | PlainMessage<PlayStoreResponse> | undefined, b: PlayStoreResponse | PlainMessage<PlayStoreResponse> | undefined): boolean {
    return proto3.util.equals(PlayStoreResponse, a, b);
  }
}

/**
 * @generated from message retrica.PlayStoreRequest
 */
export class PlayStoreRequest extends Message<PlayStoreRequest> {
  /**
   * @generated from field: string SkuId = 1;
   */
  SkuId = "";

  /**
   * @generated from field: string purchaseToken = 2;
   */
  purchaseToken = "";

  constructor(data?: PartialMessage<PlayStoreRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.PlayStoreRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "SkuId", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "purchaseToken", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PlayStoreRequest {
    return new PlayStoreRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PlayStoreRequest {
    return new PlayStoreRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PlayStoreRequest {
    return new PlayStoreRequest().fromJsonString(jsonString, options);
  }

  static equals(a: PlayStoreRequest | PlainMessage<PlayStoreRequest> | undefined, b: PlayStoreRequest | PlainMessage<PlayStoreRequest> | undefined): boolean {
    return proto3.util.equals(PlayStoreRequest, a, b);
  }
}

/**
 * [gaia]
 *
 * @generated from message retrica.GaiaModel
 */
export class GaiaModel extends Message<GaiaModel> {
  constructor(data?: PartialMessage<GaiaModel>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.GaiaModel";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GaiaModel {
    return new GaiaModel().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GaiaModel {
    return new GaiaModel().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GaiaModel {
    return new GaiaModel().fromJsonString(jsonString, options);
  }

  static equals(a: GaiaModel | PlainMessage<GaiaModel> | undefined, b: GaiaModel | PlainMessage<GaiaModel> | undefined): boolean {
    return proto3.util.equals(GaiaModel, a, b);
  }
}

/**
 * @generated from message retrica.GaiaModel.ShotComment
 */
export class GaiaModel_ShotComment extends Message<GaiaModel_ShotComment> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string shot_id = 2;
   */
  shotId = "";

  /**
   * @generated from field: retrica.Friend friend = 3;
   */
  friend?: Friend;

  /**
   * @generated from field: string text = 4;
   */
  text = "";

  /**
   * @generated from field: int64 created_at = 5;
   */
  createdAt = protoInt64.zero;

  constructor(data?: PartialMessage<GaiaModel_ShotComment>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.GaiaModel.ShotComment";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "shot_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "friend", kind: "message", T: Friend },
    { no: 4, name: "text", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "created_at", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GaiaModel_ShotComment {
    return new GaiaModel_ShotComment().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GaiaModel_ShotComment {
    return new GaiaModel_ShotComment().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GaiaModel_ShotComment {
    return new GaiaModel_ShotComment().fromJsonString(jsonString, options);
  }

  static equals(a: GaiaModel_ShotComment | PlainMessage<GaiaModel_ShotComment> | undefined, b: GaiaModel_ShotComment | PlainMessage<GaiaModel_ShotComment> | undefined): boolean {
    return proto3.util.equals(GaiaModel_ShotComment, a, b);
  }
}

/**
 * @generated from message retrica.GaiaModel.ShotDetail
 */
export class GaiaModel_ShotDetail extends Message<GaiaModel_ShotDetail> {
  /**
   * like 갯수
   *
   * @generated from field: int64 likes = 1;
   */
  likes = protoInt64.zero;

  /**
   * view 갯수
   *
   * @generated from field: int64 views = 2;
   */
  views = protoInt64.zero;

  /**
   * share 갯수
   *
   * @generated from field: int64 shares = 3;
   */
  shares = protoInt64.zero;

  /**
   * comment 갯수
   *
   * @generated from field: int64 comments = 4;
   */
  comments = protoInt64.zero;

  /**
   * 설명
   *
   * @generated from field: string description = 5;
   */
  description = "";

  /**
   * like 했는지 유무
   *
   * @generated from field: bool is_like = 6;
   */
  isLike = false;

  constructor(data?: PartialMessage<GaiaModel_ShotDetail>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.GaiaModel.ShotDetail";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "likes", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "views", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "shares", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "comments", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "is_like", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GaiaModel_ShotDetail {
    return new GaiaModel_ShotDetail().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GaiaModel_ShotDetail {
    return new GaiaModel_ShotDetail().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GaiaModel_ShotDetail {
    return new GaiaModel_ShotDetail().fromJsonString(jsonString, options);
  }

  static equals(a: GaiaModel_ShotDetail | PlainMessage<GaiaModel_ShotDetail> | undefined, b: GaiaModel_ShotDetail | PlainMessage<GaiaModel_ShotDetail> | undefined): boolean {
    return proto3.util.equals(GaiaModel_ShotDetail, a, b);
  }
}

/**
 * @generated from message retrica.GaiaModel.Shot
 */
export class GaiaModel_Shot extends Message<GaiaModel_Shot> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: retrica.Friend friend = 2;
   */
  friend?: Friend;

  /**
   * @generated from field: retrica.Content content = 3;
   */
  content?: Content;

  /**
   * @generated from field: retrica.GaiaModel.ShotDetail detail = 4;
   */
  detail?: GaiaModel_ShotDetail;

  /**
   * @generated from field: repeated string pack_product_ids = 7;
   */
  packProductIds: string[] = [];

  /**
   * @generated from field: retrica.Preset preset = 8;
   */
  preset?: Preset;

  /**
   * @generated from field: int64 created_at = 5;
   */
  createdAt = protoInt64.zero;

  /**
   * @generated from field: int64 updated_at = 6;
   */
  updatedAt = protoInt64.zero;

  /**
   * `/h1/ga/favoriteShots` 요청에 응답할 때만 이 값이 채워져서 내려갑니다. 이외에는 '0'
   *
   * @generated from field: int64 liked_at = 9;
   */
  likedAt = protoInt64.zero;

  /**
   * @generated from field: int64 followed_at = 10;
   */
  followedAt = protoInt64.zero;

  constructor(data?: PartialMessage<GaiaModel_Shot>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.GaiaModel.Shot";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "friend", kind: "message", T: Friend },
    { no: 3, name: "content", kind: "message", T: Content },
    { no: 4, name: "detail", kind: "message", T: GaiaModel_ShotDetail },
    { no: 7, name: "pack_product_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 8, name: "preset", kind: "message", T: Preset },
    { no: 5, name: "created_at", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 6, name: "updated_at", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 9, name: "liked_at", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 10, name: "followed_at", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GaiaModel_Shot {
    return new GaiaModel_Shot().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GaiaModel_Shot {
    return new GaiaModel_Shot().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GaiaModel_Shot {
    return new GaiaModel_Shot().fromJsonString(jsonString, options);
  }

  static equals(a: GaiaModel_Shot | PlainMessage<GaiaModel_Shot> | undefined, b: GaiaModel_Shot | PlainMessage<GaiaModel_Shot> | undefined): boolean {
    return proto3.util.equals(GaiaModel_Shot, a, b);
  }
}

/**
 * @generated from message retrica.GaiaModel.ShotsByOwner
 */
export class GaiaModel_ShotsByOwner extends Message<GaiaModel_ShotsByOwner> {
  /**
   * @generated from field: retrica.User user = 1;
   */
  user?: User;

  /**
   * @generated from field: repeated retrica.GaiaModel.Shot shots = 2;
   */
  shots: GaiaModel_Shot[] = [];

  constructor(data?: PartialMessage<GaiaModel_ShotsByOwner>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.GaiaModel.ShotsByOwner";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user", kind: "message", T: User },
    { no: 2, name: "shots", kind: "message", T: GaiaModel_Shot, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GaiaModel_ShotsByOwner {
    return new GaiaModel_ShotsByOwner().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GaiaModel_ShotsByOwner {
    return new GaiaModel_ShotsByOwner().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GaiaModel_ShotsByOwner {
    return new GaiaModel_ShotsByOwner().fromJsonString(jsonString, options);
  }

  static equals(a: GaiaModel_ShotsByOwner | PlainMessage<GaiaModel_ShotsByOwner> | undefined, b: GaiaModel_ShotsByOwner | PlainMessage<GaiaModel_ShotsByOwner> | undefined): boolean {
    return proto3.util.equals(GaiaModel_ShotsByOwner, a, b);
  }
}

/**
 * @generated from message retrica.GaiaModel.FeedItemShotContent
 */
export class GaiaModel_FeedItemShotContent extends Message<GaiaModel_FeedItemShotContent> {
  /**
   * @generated from field: string shot_id = 1;
   */
  shotId = "";

  /**
   * @generated from field: retrica.GaiaModel.Shot shot = 2;
   */
  shot?: GaiaModel_Shot;

  constructor(data?: PartialMessage<GaiaModel_FeedItemShotContent>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.GaiaModel.FeedItemShotContent";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "shot_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "shot", kind: "message", T: GaiaModel_Shot },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GaiaModel_FeedItemShotContent {
    return new GaiaModel_FeedItemShotContent().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GaiaModel_FeedItemShotContent {
    return new GaiaModel_FeedItemShotContent().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GaiaModel_FeedItemShotContent {
    return new GaiaModel_FeedItemShotContent().fromJsonString(jsonString, options);
  }

  static equals(a: GaiaModel_FeedItemShotContent | PlainMessage<GaiaModel_FeedItemShotContent> | undefined, b: GaiaModel_FeedItemShotContent | PlainMessage<GaiaModel_FeedItemShotContent> | undefined): boolean {
    return proto3.util.equals(GaiaModel_FeedItemShotContent, a, b);
  }
}

/**
 * @generated from message retrica.GaiaModel.FeedItemProductContent
 */
export class GaiaModel_FeedItemProductContent extends Message<GaiaModel_FeedItemProductContent> {
  /**
   * @generated from field: string product_id = 1;
   */
  productId = "";

  /**
   * @generated from field: retrica.Content content = 2;
   */
  content?: Content;

  /**
   * @generated from field: retrica.Store.Product product = 3;
   */
  product?: Store_Product;

  constructor(data?: PartialMessage<GaiaModel_FeedItemProductContent>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.GaiaModel.FeedItemProductContent";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "product_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "content", kind: "message", T: Content },
    { no: 3, name: "product", kind: "message", T: Store_Product },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GaiaModel_FeedItemProductContent {
    return new GaiaModel_FeedItemProductContent().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GaiaModel_FeedItemProductContent {
    return new GaiaModel_FeedItemProductContent().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GaiaModel_FeedItemProductContent {
    return new GaiaModel_FeedItemProductContent().fromJsonString(jsonString, options);
  }

  static equals(a: GaiaModel_FeedItemProductContent | PlainMessage<GaiaModel_FeedItemProductContent> | undefined, b: GaiaModel_FeedItemProductContent | PlainMessage<GaiaModel_FeedItemProductContent> | undefined): boolean {
    return proto3.util.equals(GaiaModel_FeedItemProductContent, a, b);
  }
}

/**
 * @generated from message retrica.GaiaModel.FeedItemQuoteContent
 */
export class GaiaModel_FeedItemQuoteContent extends Message<GaiaModel_FeedItemQuoteContent> {
  /**
   * @generated from field: retrica.Content content = 1;
   */
  content?: Content;

  constructor(data?: PartialMessage<GaiaModel_FeedItemQuoteContent>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.GaiaModel.FeedItemQuoteContent";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "content", kind: "message", T: Content },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GaiaModel_FeedItemQuoteContent {
    return new GaiaModel_FeedItemQuoteContent().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GaiaModel_FeedItemQuoteContent {
    return new GaiaModel_FeedItemQuoteContent().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GaiaModel_FeedItemQuoteContent {
    return new GaiaModel_FeedItemQuoteContent().fromJsonString(jsonString, options);
  }

  static equals(a: GaiaModel_FeedItemQuoteContent | PlainMessage<GaiaModel_FeedItemQuoteContent> | undefined, b: GaiaModel_FeedItemQuoteContent | PlainMessage<GaiaModel_FeedItemQuoteContent> | undefined): boolean {
    return proto3.util.equals(GaiaModel_FeedItemQuoteContent, a, b);
  }
}

/**
 * @generated from message retrica.GaiaModel.FeedItemLinkContent
 */
export class GaiaModel_FeedItemLinkContent extends Message<GaiaModel_FeedItemLinkContent> {
  /**
   * @generated from field: string link = 1;
   */
  link = "";

  /**
   * @generated from field: retrica.Content content = 2;
   */
  content?: Content;

  constructor(data?: PartialMessage<GaiaModel_FeedItemLinkContent>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.GaiaModel.FeedItemLinkContent";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "link", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "content", kind: "message", T: Content },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GaiaModel_FeedItemLinkContent {
    return new GaiaModel_FeedItemLinkContent().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GaiaModel_FeedItemLinkContent {
    return new GaiaModel_FeedItemLinkContent().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GaiaModel_FeedItemLinkContent {
    return new GaiaModel_FeedItemLinkContent().fromJsonString(jsonString, options);
  }

  static equals(a: GaiaModel_FeedItemLinkContent | PlainMessage<GaiaModel_FeedItemLinkContent> | undefined, b: GaiaModel_FeedItemLinkContent | PlainMessage<GaiaModel_FeedItemLinkContent> | undefined): boolean {
    return proto3.util.equals(GaiaModel_FeedItemLinkContent, a, b);
  }
}

/**
 * @generated from message retrica.GaiaModel.FeedItem
 */
export class GaiaModel_FeedItem extends Message<GaiaModel_FeedItem> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: int64 updated_at = 2;
   */
  updatedAt = protoInt64.zero;

  /**
   * @generated from field: retrica.FeedItemType type = 3;
   */
  type = FeedItemType.FI_TYPE_SHOT;

  /**
   * @generated from field: retrica.GaiaModel.FeedItemShotContent shotContent = 4;
   */
  shotContent?: GaiaModel_FeedItemShotContent;

  /**
   * @generated from field: retrica.GaiaModel.FeedItemProductContent productContent = 5;
   */
  productContent?: GaiaModel_FeedItemProductContent;

  /**
   * @generated from field: retrica.GaiaModel.FeedItemQuoteContent quoteContent = 6;
   */
  quoteContent?: GaiaModel_FeedItemQuoteContent;

  /**
   * @generated from field: retrica.GaiaModel.FeedItemLinkContent linkContent = 7;
   */
  linkContent?: GaiaModel_FeedItemLinkContent;

  constructor(data?: PartialMessage<GaiaModel_FeedItem>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.GaiaModel.FeedItem";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "updated_at", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "type", kind: "enum", T: proto3.getEnumType(FeedItemType) },
    { no: 4, name: "shotContent", kind: "message", T: GaiaModel_FeedItemShotContent },
    { no: 5, name: "productContent", kind: "message", T: GaiaModel_FeedItemProductContent },
    { no: 6, name: "quoteContent", kind: "message", T: GaiaModel_FeedItemQuoteContent },
    { no: 7, name: "linkContent", kind: "message", T: GaiaModel_FeedItemLinkContent },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GaiaModel_FeedItem {
    return new GaiaModel_FeedItem().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GaiaModel_FeedItem {
    return new GaiaModel_FeedItem().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GaiaModel_FeedItem {
    return new GaiaModel_FeedItem().fromJsonString(jsonString, options);
  }

  static equals(a: GaiaModel_FeedItem | PlainMessage<GaiaModel_FeedItem> | undefined, b: GaiaModel_FeedItem | PlainMessage<GaiaModel_FeedItem> | undefined): boolean {
    return proto3.util.equals(GaiaModel_FeedItem, a, b);
  }
}

/**
 * @generated from message retrica.GaiaModel.Profile
 */
export class GaiaModel_Profile extends Message<GaiaModel_Profile> {
  /**
   * @generated from field: retrica.Friend friend = 1;
   */
  friend?: Friend;

  /**
   * @generated from field: int64 likes = 2;
   */
  likes = protoInt64.zero;

  /**
   * @generated from field: int64 following = 3;
   */
  following = protoInt64.zero;

  /**
   * @generated from field: int64 followers = 4;
   */
  followers = protoInt64.zero;

  /**
   * @generated from field: bool is_subscribe = 5;
   */
  isSubscribe = false;

  constructor(data?: PartialMessage<GaiaModel_Profile>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.GaiaModel.Profile";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "friend", kind: "message", T: Friend },
    { no: 2, name: "likes", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "following", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "followers", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "is_subscribe", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GaiaModel_Profile {
    return new GaiaModel_Profile().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GaiaModel_Profile {
    return new GaiaModel_Profile().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GaiaModel_Profile {
    return new GaiaModel_Profile().fromJsonString(jsonString, options);
  }

  static equals(a: GaiaModel_Profile | PlainMessage<GaiaModel_Profile> | undefined, b: GaiaModel_Profile | PlainMessage<GaiaModel_Profile> | undefined): boolean {
    return proto3.util.equals(GaiaModel_Profile, a, b);
  }
}

/**
 * @generated from message retrica.GaiaModel.Alert
 */
export class GaiaModel_Alert extends Message<GaiaModel_Alert> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: retrica.GaiaModel.Alert.Front front = 2;
   */
  front?: GaiaModel_Alert_Front;

  /**
   * @generated from field: retrica.GaiaModel.Alert.Middle middle = 3;
   */
  middle?: GaiaModel_Alert_Middle;

  /**
   * @generated from field: retrica.GaiaModel.Alert.Back back = 4;
   */
  back?: GaiaModel_Alert_Back;

  /**
   * 생성날짜
   *
   * @generated from field: int64 created_at = 5;
   */
  createdAt = protoInt64.zero;

  constructor(data?: PartialMessage<GaiaModel_Alert>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.GaiaModel.Alert";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "front", kind: "message", T: GaiaModel_Alert_Front },
    { no: 3, name: "middle", kind: "message", T: GaiaModel_Alert_Middle },
    { no: 4, name: "back", kind: "message", T: GaiaModel_Alert_Back },
    { no: 5, name: "created_at", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GaiaModel_Alert {
    return new GaiaModel_Alert().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GaiaModel_Alert {
    return new GaiaModel_Alert().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GaiaModel_Alert {
    return new GaiaModel_Alert().fromJsonString(jsonString, options);
  }

  static equals(a: GaiaModel_Alert | PlainMessage<GaiaModel_Alert> | undefined, b: GaiaModel_Alert | PlainMessage<GaiaModel_Alert> | undefined): boolean {
    return proto3.util.equals(GaiaModel_Alert, a, b);
  }
}

/**
 * @generated from message retrica.GaiaModel.Alert.Front
 */
export class GaiaModel_Alert_Front extends Message<GaiaModel_Alert_Front> {
  /**
   * 앞 이미지
   *
   * @generated from field: string image_url = 1;
   */
  imageUrl = "";

  /**
   * deeplink
   *
   * @generated from field: string deeplink = 2;
   */
  deeplink = "";

  constructor(data?: PartialMessage<GaiaModel_Alert_Front>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.GaiaModel.Alert.Front";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "image_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "deeplink", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GaiaModel_Alert_Front {
    return new GaiaModel_Alert_Front().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GaiaModel_Alert_Front {
    return new GaiaModel_Alert_Front().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GaiaModel_Alert_Front {
    return new GaiaModel_Alert_Front().fromJsonString(jsonString, options);
  }

  static equals(a: GaiaModel_Alert_Front | PlainMessage<GaiaModel_Alert_Front> | undefined, b: GaiaModel_Alert_Front | PlainMessage<GaiaModel_Alert_Front> | undefined): boolean {
    return proto3.util.equals(GaiaModel_Alert_Front, a, b);
  }
}

/**
 * @generated from message retrica.GaiaModel.Alert.Middle
 */
export class GaiaModel_Alert_Middle extends Message<GaiaModel_Alert_Middle> {
  /**
   * fomat string(%s)
   *
   * @generated from field: string format = 1;
   */
  format = "";

  /**
   * placeholder 값들
   *
   * @generated from field: repeated string format_placeholders = 2;
   */
  formatPlaceholders: string[] = [];

  /**
   *  placeholder에 해당 deeplink
   *
   * @generated from field: repeated string format_deeplinks = 3;
   */
  formatDeeplinks: string[] = [];

  /**
   * middle 전체 영역 deeplink
   *
   * @generated from field: string deeplink = 4;
   */
  deeplink = "";

  constructor(data?: PartialMessage<GaiaModel_Alert_Middle>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.GaiaModel.Alert.Middle";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "format", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "format_placeholders", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "format_deeplinks", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "deeplink", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GaiaModel_Alert_Middle {
    return new GaiaModel_Alert_Middle().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GaiaModel_Alert_Middle {
    return new GaiaModel_Alert_Middle().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GaiaModel_Alert_Middle {
    return new GaiaModel_Alert_Middle().fromJsonString(jsonString, options);
  }

  static equals(a: GaiaModel_Alert_Middle | PlainMessage<GaiaModel_Alert_Middle> | undefined, b: GaiaModel_Alert_Middle | PlainMessage<GaiaModel_Alert_Middle> | undefined): boolean {
    return proto3.util.equals(GaiaModel_Alert_Middle, a, b);
  }
}

/**
 * @generated from message retrica.GaiaModel.Alert.Back
 */
export class GaiaModel_Alert_Back extends Message<GaiaModel_Alert_Back> {
  /**
   * 뒤 이미지
   *
   * @generated from field: string image_url = 1;
   */
  imageUrl = "";

  /**
   * deeplink
   *
   * @generated from field: string deeplink = 2;
   */
  deeplink = "";

  constructor(data?: PartialMessage<GaiaModel_Alert_Back>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.GaiaModel.Alert.Back";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "image_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "deeplink", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GaiaModel_Alert_Back {
    return new GaiaModel_Alert_Back().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GaiaModel_Alert_Back {
    return new GaiaModel_Alert_Back().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GaiaModel_Alert_Back {
    return new GaiaModel_Alert_Back().fromJsonString(jsonString, options);
  }

  static equals(a: GaiaModel_Alert_Back | PlainMessage<GaiaModel_Alert_Back> | undefined, b: GaiaModel_Alert_Back | PlainMessage<GaiaModel_Alert_Back> | undefined): boolean {
    return proto3.util.equals(GaiaModel_Alert_Back, a, b);
  }
}

/**
 * @generated from message retrica.GaiaModel.HashTag
 */
export class GaiaModel_HashTag extends Message<GaiaModel_HashTag> {
  /**
   * @generated from field: string tag = 1;
   */
  tag = "";

  /**
   * @generated from field: string display = 2;
   */
  display = "";

  /**
   * @generated from field: string description = 3;
   */
  description = "";

  /**
   * @generated from field: int64 total = 4;
   */
  total = protoInt64.zero;

  constructor(data?: PartialMessage<GaiaModel_HashTag>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.GaiaModel.HashTag";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "tag", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "display", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "total", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GaiaModel_HashTag {
    return new GaiaModel_HashTag().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GaiaModel_HashTag {
    return new GaiaModel_HashTag().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GaiaModel_HashTag {
    return new GaiaModel_HashTag().fromJsonString(jsonString, options);
  }

  static equals(a: GaiaModel_HashTag | PlainMessage<GaiaModel_HashTag> | undefined, b: GaiaModel_HashTag | PlainMessage<GaiaModel_HashTag> | undefined): boolean {
    return proto3.util.equals(GaiaModel_HashTag, a, b);
  }
}

/**
 * [tag content]
 *
 * @generated from message retrica.TagContent
 */
export class TagContent extends Message<TagContent> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * TagContent가 Shot으로 이뤄진 경우 (Optional)
   *
   * @generated from field: retrica.GaiaModel.Shot shot = 2;
   */
  shot?: GaiaModel_Shot;

  /**
   * Tagcontent의 내용 (Optional)
   *
   * @generated from field: retrica.Content content = 3;
   */
  content?: Content;

  constructor(data?: PartialMessage<TagContent>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.TagContent";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "shot", kind: "message", T: GaiaModel_Shot },
    { no: 3, name: "content", kind: "message", T: Content },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TagContent {
    return new TagContent().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TagContent {
    return new TagContent().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TagContent {
    return new TagContent().fromJsonString(jsonString, options);
  }

  static equals(a: TagContent | PlainMessage<TagContent> | undefined, b: TagContent | PlainMessage<TagContent> | undefined): boolean {
    return proto3.util.equals(TagContent, a, b);
  }
}

/**
 * [pack, lens, filter]
 *
 * @generated from message retrica.FilterSpecV1
 */
export class FilterSpecV1 extends Message<FilterSpecV1> {
  /**
   * @generated from field: string lookup_url = 1;
   */
  lookupUrl = "";

  /**
   * @generated from field: string lookup2_url = 2;
   */
  lookup2Url = "";

  /**
   * @generated from field: int32 multi_lf_style = 3;
   */
  multiLfStyle = 0;

  /**
   * vignette
   *
   * @generated from field: int32 v_first = 4;
   */
  vFirst = 0;

  /**
   * @generated from field: int32 v_type = 5;
   */
  vType = 0;

  /**
   * @generated from field: float v_start = 6;
   */
  vStart = 0;

  /**
   * @generated from field: float v_end = 7;
   */
  vEnd = 0;

  /**
   * @generated from field: string v_color = 8;
   */
  vColor = "";

  /**
   * vignette highlight
   *
   * 1: on, others: off
   *
   * @generated from field: int32 vh_use = 9;
   */
  vhUse = 0;

  /**
   * @generated from field: float vh_weight = 10;
   */
  vhWeight = 0;

  /**
   * @generated from field: float vh_end = 11;
   */
  vhEnd = 0;

  /**
   * beauty
   *
   * 1: on, others: off
   *
   * @generated from field: int32 b_use = 12;
   */
  bUse = 0;

  /**
   * @generated from field: float b_intensity = 13;
   */
  bIntensity = 0;

  /**
   * color.base
   *
   * @generated from field: float contrast = 14;
   */
  contrast = 0;

  /**
   * @generated from field: float brightness = 15;
   */
  brightness = 0;

  /**
   * @generated from field: float saturation = 16;
   */
  saturation = 0;

  /**
   * @generated from field: float hue = 17;
   */
  hue = 0;

  /**
   * blend
   *
   * if not nil, then use blend filter
   *
   * @generated from field: string bl_url = 18;
   */
  blUrl = "";

  /**
   * @generated from field: float bl_intensity = 19;
   */
  blIntensity = 0;

  /**
   * @generated from field: float bl_interval = 20;
   */
  blInterval = 0;

  /**
   * @generated from field: string bl_steps = 21;
   */
  blSteps = "";

  /**
   * sharpen
   *
   * 1: on, others: off
   *
   * @generated from field: int32 sp_use = 22;
   */
  spUse = 0;

  /**
   * @generated from field: float sp_intensity = 23;
   */
  spIntensity = 0;

  /**
   * only rendered(camera action)
   *
   * @generated from field: int32 only_rendered = 24;
   */
  onlyRendered = 0;

  /**
   * other filters
   *
   * @generated from field: int32 dt_logo = 25;
   */
  dtLogo = 0;

  /**
   * @generated from field: int32 no_ch_aberration = 26;
   */
  noChAberration = 0;

  /**
   * @generated from field: int32 no_vhs_distortion = 27;
   */
  noVhsDistortion = 0;

  /**
   * @generated from field: int32 no_vcr_distortion = 28;
   */
  noVcrDistortion = 0;

  /**
   * @generated from field: int32 no_crt_cross = 29;
   */
  noCrtCross = 0;

  /**
   * grain
   *
   * @generated from field: int32 gr_use = 30;
   */
  grUse = 0;

  /**
   * @generated from field: float gr_amount = 31;
   */
  grAmount = 0;

  /**
   * @generated from field: float gr_size = 32;
   */
  grSize = 0;

  /**
   * @generated from field: float gr_roughness = 33;
   */
  grRoughness = 0;

  /**
   * clarity
   *
   * 1: on, others: off
   *
   * @generated from field: int32 cl_use = 34;
   */
  clUse = 0;

  /**
   * @generated from field: float cl_intensity = 35;
   */
  clIntensity = 0;

  constructor(data?: PartialMessage<FilterSpecV1>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.FilterSpecV1";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "lookup_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "lookup2_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "multi_lf_style", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "v_first", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "v_type", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "v_start", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 7, name: "v_end", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 8, name: "v_color", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "vh_use", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 10, name: "vh_weight", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 11, name: "vh_end", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 12, name: "b_use", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 13, name: "b_intensity", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 14, name: "contrast", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 15, name: "brightness", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 16, name: "saturation", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 17, name: "hue", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 18, name: "bl_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 19, name: "bl_intensity", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 20, name: "bl_interval", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 21, name: "bl_steps", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 22, name: "sp_use", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 23, name: "sp_intensity", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 24, name: "only_rendered", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 25, name: "dt_logo", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 26, name: "no_ch_aberration", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 27, name: "no_vhs_distortion", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 28, name: "no_vcr_distortion", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 29, name: "no_crt_cross", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 30, name: "gr_use", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 31, name: "gr_amount", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 32, name: "gr_size", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 33, name: "gr_roughness", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 34, name: "cl_use", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 35, name: "cl_intensity", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FilterSpecV1 {
    return new FilterSpecV1().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FilterSpecV1 {
    return new FilterSpecV1().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FilterSpecV1 {
    return new FilterSpecV1().fromJsonString(jsonString, options);
  }

  static equals(a: FilterSpecV1 | PlainMessage<FilterSpecV1> | undefined, b: FilterSpecV1 | PlainMessage<FilterSpecV1> | undefined): boolean {
    return proto3.util.equals(FilterSpecV1, a, b);
  }
}

/**
 * @generated from message retrica.Lens
 */
export class Lens extends Message<Lens> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string title = 2;
   */
  title = "";

  /**
   * 6자리의 RGB hex code '#'는 미포함
   *
   * @generated from field: string sample_color = 3;
   */
  sampleColor = "";

  /**
   * @generated from field: string sample_image_url = 4;
   */
  sampleImageUrl = "";

  /**
   * @generated from field: retrica.FilterSpecV1 filter_spec = 5;
   */
  filterSpec?: FilterSpecV1;

  constructor(data?: PartialMessage<Lens>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.Lens";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "sample_color", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "sample_image_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "filter_spec", kind: "message", T: FilterSpecV1 },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Lens {
    return new Lens().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Lens {
    return new Lens().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Lens {
    return new Lens().fromJsonString(jsonString, options);
  }

  static equals(a: Lens | PlainMessage<Lens> | undefined, b: Lens | PlainMessage<Lens> | undefined): boolean {
    return proto3.util.equals(Lens, a, b);
  }
}

/**
 * @generated from message retrica.Pack
 */
export class Pack extends Message<Pack> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string title = 2;
   */
  title = "";

  /**
   * 6자리의 RGB hex code '#'는 미포함
   *
   * @generated from field: string sample_color = 3;
   */
  sampleColor = "";

  /**
   * @generated from field: string sample_image_url = 4;
   */
  sampleImageUrl = "";

  /**
   * @generated from field: repeated retrica.Lens lenses = 5;
   */
  lenses: Lens[] = [];

  /**
   * @generated from field: retrica.ProductPlan productPlan = 6;
   */
  productPlan = ProductPlan.PTP_NONE;

  constructor(data?: PartialMessage<Pack>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.Pack";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "sample_color", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "sample_image_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "lenses", kind: "message", T: Lens, repeated: true },
    { no: 6, name: "productPlan", kind: "enum", T: proto3.getEnumType(ProductPlan) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Pack {
    return new Pack().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Pack {
    return new Pack().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Pack {
    return new Pack().fromJsonString(jsonString, options);
  }

  static equals(a: Pack | PlainMessage<Pack> | undefined, b: Pack | PlainMessage<Pack> | undefined): boolean {
    return proto3.util.equals(Pack, a, b);
  }
}

/**
 * @generated from message retrica.PackList
 */
export class PackList extends Message<PackList> {
  /**
   * @generated from field: repeated retrica.Pack packs = 1;
   */
  packs: Pack[] = [];

  /**
   * @generated from field: repeated string recommend_lens_ids = 2;
   */
  recommendLensIds: string[] = [];

  /**
   * @generated from field: string default_lens_id = 3;
   */
  defaultLensId = "";

  constructor(data?: PartialMessage<PackList>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.PackList";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "packs", kind: "message", T: Pack, repeated: true },
    { no: 2, name: "recommend_lens_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "default_lens_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PackList {
    return new PackList().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PackList {
    return new PackList().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PackList {
    return new PackList().fromJsonString(jsonString, options);
  }

  static equals(a: PackList | PlainMessage<PackList> | undefined, b: PackList | PlainMessage<PackList> | undefined): boolean {
    return proto3.util.equals(PackList, a, b);
  }
}

/**
 * @generated from message retrica.SuperInit
 */
export class SuperInit extends Message<SuperInit> {
  /**
   * @generated from field: int32 version = 1;
   */
  version = 0;

  /**
   * @generated from field: int32 packListVersion = 2;
   */
  packListVersion = 0;

  /**
   * @generated from field: int32 productListVersion = 3;
   */
  productListVersion = 0;

  constructor(data?: PartialMessage<SuperInit>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.SuperInit";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "version", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "packListVersion", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "productListVersion", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SuperInit {
    return new SuperInit().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SuperInit {
    return new SuperInit().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SuperInit {
    return new SuperInit().fromJsonString(jsonString, options);
  }

  static equals(a: SuperInit | PlainMessage<SuperInit> | undefined, b: SuperInit | PlainMessage<SuperInit> | undefined): boolean {
    return proto3.util.equals(SuperInit, a, b);
  }
}

/**
 * @generated from message retrica.WhiteBalance
 */
export class WhiteBalance extends Message<WhiteBalance> {
  /**
   * @generated from field: uint32 temperature = 1;
   */
  temperature = 0;

  /**
   * @generated from field: sint32 tint = 2;
   */
  tint = 0;

  constructor(data?: PartialMessage<WhiteBalance>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.WhiteBalance";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "temperature", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "tint", kind: "scalar", T: 17 /* ScalarType.SINT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): WhiteBalance {
    return new WhiteBalance().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): WhiteBalance {
    return new WhiteBalance().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): WhiteBalance {
    return new WhiteBalance().fromJsonString(jsonString, options);
  }

  static equals(a: WhiteBalance | PlainMessage<WhiteBalance> | undefined, b: WhiteBalance | PlainMessage<WhiteBalance> | undefined): boolean {
    return proto3.util.equals(WhiteBalance, a, b);
  }
}

/**
 * @generated from message retrica.Exposure
 */
export class Exposure extends Message<Exposure> {
  /**
   * @generated from field: float bias = 1;
   */
  bias = 0;

  /**
   * @generated from field: uint32 iso = 2;
   */
  iso = 0;

  /**
   * @generated from field: float shutter_speed = 3;
   */
  shutterSpeed = 0;

  constructor(data?: PartialMessage<Exposure>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.Exposure";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "bias", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 2, name: "iso", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 3, name: "shutter_speed", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Exposure {
    return new Exposure().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Exposure {
    return new Exposure().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Exposure {
    return new Exposure().fromJsonString(jsonString, options);
  }

  static equals(a: Exposure | PlainMessage<Exposure> | undefined, b: Exposure | PlainMessage<Exposure> | undefined): boolean {
    return proto3.util.equals(Exposure, a, b);
  }
}

/**
 * @generated from message retrica.CameraConfiguration
 */
export class CameraConfiguration extends Message<CameraConfiguration> {
  /**
   * @generated from field: retrica.WhiteBalance white_balance = 1;
   */
  whiteBalance?: WhiteBalance;

  /**
   * @generated from field: retrica.Exposure exposure = 2;
   */
  exposure?: Exposure;

  /**
   * @generated from field: retrica.CameraConfiguration.Flash flash = 3;
   */
  flash = CameraConfiguration_Flash.OFF;

  /**
   * @generated from field: retrica.CameraConfiguration.DeviceType device_type = 4;
   */
  deviceType = CameraConfiguration_DeviceType.DT_WIDE_ANGLE;

  /**
   * @generated from field: retrica.CameraConfiguration.Position position = 5;
   */
  position = CameraConfiguration_Position.POS_UNSPECIFIED;

  /**
   * @generated from field: string format = 6;
   */
  format = "";

  /**
   * @generated from field: retrica.CameraConfiguration.CameraRatio camera_ratio = 7;
   */
  cameraRatio = CameraConfiguration_CameraRatio.CRT_4x3;

  /**
   * @generated from field: float zoom = 8;
   */
  zoom = 0;

  constructor(data?: PartialMessage<CameraConfiguration>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.CameraConfiguration";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "white_balance", kind: "message", T: WhiteBalance },
    { no: 2, name: "exposure", kind: "message", T: Exposure },
    { no: 3, name: "flash", kind: "enum", T: proto3.getEnumType(CameraConfiguration_Flash) },
    { no: 4, name: "device_type", kind: "enum", T: proto3.getEnumType(CameraConfiguration_DeviceType) },
    { no: 5, name: "position", kind: "enum", T: proto3.getEnumType(CameraConfiguration_Position) },
    { no: 6, name: "format", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "camera_ratio", kind: "enum", T: proto3.getEnumType(CameraConfiguration_CameraRatio) },
    { no: 8, name: "zoom", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CameraConfiguration {
    return new CameraConfiguration().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CameraConfiguration {
    return new CameraConfiguration().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CameraConfiguration {
    return new CameraConfiguration().fromJsonString(jsonString, options);
  }

  static equals(a: CameraConfiguration | PlainMessage<CameraConfiguration> | undefined, b: CameraConfiguration | PlainMessage<CameraConfiguration> | undefined): boolean {
    return proto3.util.equals(CameraConfiguration, a, b);
  }
}

/**
 * 참고 : protobuf 특성상, enum 0 는 실제 bytes on wire 로 encoding 되지 않고,
 * protobuf library 가 decoding 시 field 값이 비어있으면 default 0 로 디코딩합니다.
 *
 * @generated from enum retrica.CameraConfiguration.Flash
 */
export enum CameraConfiguration_Flash {
  /**
   * @generated from enum value: FLASH_OFF = 0;
   */
  OFF = 0,

  /**
   * @generated from enum value: FLASH_ON = 1;
   */
  ON = 1,

  /**
   * @generated from enum value: FLASH_AUTO = 2;
   */
  AUTO = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(CameraConfiguration_Flash)
proto3.util.setEnumType(CameraConfiguration_Flash, "retrica.CameraConfiguration.Flash", [
  { no: 0, name: "FLASH_OFF" },
  { no: 1, name: "FLASH_ON" },
  { no: 2, name: "FLASH_AUTO" },
]);

/**
 * @generated from enum retrica.CameraConfiguration.DeviceType
 */
export enum CameraConfiguration_DeviceType {
  /**
   * @generated from enum value: DT_WIDE_ANGLE = 0;
   */
  DT_WIDE_ANGLE = 0,

  /**
   * @generated from enum value: DT_TELEPHOTO = 1;
   */
  DT_TELEPHOTO = 1,

  /**
   * @generated from enum value: DT_ULTRA_WIDE = 2;
   */
  DT_ULTRA_WIDE = 2,

  /**
   * @generated from enum value: DT_DUAL = 3;
   */
  DT_DUAL = 3,

  /**
   * @generated from enum value: DT_DUAL_WIDE = 4;
   */
  DT_DUAL_WIDE = 4,

  /**
   * @generated from enum value: DT_TRIPLE = 5;
   */
  DT_TRIPLE = 5,

  /**
   * @generated from enum value: DT_TRUE_DEPTH = 6;
   */
  DT_TRUE_DEPTH = 6,

  /**
   * @generated from enum value: DT_LIDAR_DEPTH = 7;
   */
  DT_LIDAR_DEPTH = 7,

  /**
   * 자동 lens 선택 모드
   *
   * @generated from enum value: DT_AUTO = 8;
   */
  DT_AUTO = 8,
}
// Retrieve enum metadata with: proto3.getEnumType(CameraConfiguration_DeviceType)
proto3.util.setEnumType(CameraConfiguration_DeviceType, "retrica.CameraConfiguration.DeviceType", [
  { no: 0, name: "DT_WIDE_ANGLE" },
  { no: 1, name: "DT_TELEPHOTO" },
  { no: 2, name: "DT_ULTRA_WIDE" },
  { no: 3, name: "DT_DUAL" },
  { no: 4, name: "DT_DUAL_WIDE" },
  { no: 5, name: "DT_TRIPLE" },
  { no: 6, name: "DT_TRUE_DEPTH" },
  { no: 7, name: "DT_LIDAR_DEPTH" },
  { no: 8, name: "DT_AUTO" },
]);

/**
 * @generated from enum retrica.CameraConfiguration.Position
 */
export enum CameraConfiguration_Position {
  /**
   * @generated from enum value: POS_UNSPECIFIED = 0;
   */
  POS_UNSPECIFIED = 0,

  /**
   * @generated from enum value: POS_BACK = 1;
   */
  POS_BACK = 1,

  /**
   * @generated from enum value: POS_FRONT = 2;
   */
  POS_FRONT = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(CameraConfiguration_Position)
proto3.util.setEnumType(CameraConfiguration_Position, "retrica.CameraConfiguration.Position", [
  { no: 0, name: "POS_UNSPECIFIED" },
  { no: 1, name: "POS_BACK" },
  { no: 2, name: "POS_FRONT" },
]);

/**
 * @generated from enum retrica.CameraConfiguration.CameraRatio
 */
export enum CameraConfiguration_CameraRatio {
  /**
   * @generated from enum value: CRT_4x3 = 0;
   */
  CRT_4x3 = 0,

  /**
   * @generated from enum value: CRT_16x9 = 1;
   */
  CRT_16x9 = 1,
}
// Retrieve enum metadata with: proto3.getEnumType(CameraConfiguration_CameraRatio)
proto3.util.setEnumType(CameraConfiguration_CameraRatio, "retrica.CameraConfiguration.CameraRatio", [
  { no: 0, name: "CRT_4x3" },
  { no: 1, name: "CRT_16x9" },
]);

/**
 * @generated from message retrica.ImageProcessing
 */
export class ImageProcessing extends Message<ImageProcessing> {
  /**
   * @generated from field: repeated retrica.ImageProcess image_processes = 1;
   */
  imageProcesses: ImageProcess[] = [];

  constructor(data?: PartialMessage<ImageProcessing>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.ImageProcessing";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "image_processes", kind: "message", T: ImageProcess, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ImageProcessing {
    return new ImageProcessing().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ImageProcessing {
    return new ImageProcessing().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ImageProcessing {
    return new ImageProcessing().fromJsonString(jsonString, options);
  }

  static equals(a: ImageProcessing | PlainMessage<ImageProcessing> | undefined, b: ImageProcessing | PlainMessage<ImageProcessing> | undefined): boolean {
    return proto3.util.equals(ImageProcessing, a, b);
  }
}

/**
 * @generated from message retrica.Preset
 */
export class Preset extends Message<Preset> {
  /**
   * @generated from field: uint32 version = 1;
   */
  version = 0;

  /**
   * @generated from field: string id = 2;
   */
  id = "";

  /**
   * required
   *
   * @generated from field: retrica.Preset.Metadata metadata = 3;
   */
  metadata?: Preset_Metadata;

  /**
   * 4 ~ 10 reserved 
   *
   * @generated from field: retrica.CameraConfiguration camera_config = 11;
   */
  cameraConfig?: CameraConfiguration;

  /**
   *  bytes canvas = 13;    // 우선 spec 에서 제외합니다. https://retrica.slack.com/archives/C3M2G7FT9/p1719474981218789?thread_ts=1719454413.715039&cid=C3M2G7FT9
   *
   * @generated from field: retrica.ImageProcessing image_processing = 12;
   */
  imageProcessing?: ImageProcessing;

  /**
   * @generated from field: retrica.Preset.ActionTimestamps action_timestamps = 4;
   */
  actionTimestamps?: Preset_ActionTimestamps;

  constructor(data?: PartialMessage<Preset>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.Preset";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "version", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "metadata", kind: "message", T: Preset_Metadata },
    { no: 11, name: "camera_config", kind: "message", T: CameraConfiguration },
    { no: 12, name: "image_processing", kind: "message", T: ImageProcessing },
    { no: 4, name: "action_timestamps", kind: "message", T: Preset_ActionTimestamps },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Preset {
    return new Preset().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Preset {
    return new Preset().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Preset {
    return new Preset().fromJsonString(jsonString, options);
  }

  static equals(a: Preset | PlainMessage<Preset> | undefined, b: Preset | PlainMessage<Preset> | undefined): boolean {
    return proto3.util.equals(Preset, a, b);
  }
}

/**
 * required 필드가 비어 있을 경우 Add preset 거부합니다.
 *
 * @generated from message retrica.Preset.Metadata
 */
export class Preset_Metadata extends Message<Preset_Metadata> {
  /**
   * required
   *
   * @generated from field: string title = 1;
   */
  title = "";

  /**
   * @generated from field: string description = 2;
   */
  description = "";

  /**
   * required
   *
   * @generated from field: string sample_color = 3;
   */
  sampleColor = "";

  /**
   * required
   *
   * @generated from field: string preview_url = 4;
   */
  previewUrl = "";

  /**
   * required
   *
   * @generated from field: string origin_url = 5;
   */
  originUrl = "";

  /**
   * @generated from field: retrica.Preset.Metadata.Author author = 6;
   */
  author?: Preset_Metadata_Author;

  /**
   * default false
   *
   * @generated from field: bool hidden = 7;
   */
  hidden = false;

  constructor(data?: PartialMessage<Preset_Metadata>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.Preset.Metadata";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "sample_color", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "preview_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "origin_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "author", kind: "message", T: Preset_Metadata_Author },
    { no: 7, name: "hidden", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Preset_Metadata {
    return new Preset_Metadata().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Preset_Metadata {
    return new Preset_Metadata().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Preset_Metadata {
    return new Preset_Metadata().fromJsonString(jsonString, options);
  }

  static equals(a: Preset_Metadata | PlainMessage<Preset_Metadata> | undefined, b: Preset_Metadata | PlainMessage<Preset_Metadata> | undefined): boolean {
    return proto3.util.equals(Preset_Metadata, a, b);
  }
}

/**
 * @generated from message retrica.Preset.Metadata.Author
 */
export class Preset_Metadata_Author extends Message<Preset_Metadata_Author> {
  /**
   * Add preset 할 때 client 에서 id 만 채워주면 username, fullname 은 서버쪽에서 채워넣습니다.
   *
   * required
   *
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string username = 2;
   */
  username = "";

  /**
   * @generated from field: string fullname = 3;
   */
  fullname = "";

  constructor(data?: PartialMessage<Preset_Metadata_Author>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.Preset.Metadata.Author";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "fullname", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Preset_Metadata_Author {
    return new Preset_Metadata_Author().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Preset_Metadata_Author {
    return new Preset_Metadata_Author().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Preset_Metadata_Author {
    return new Preset_Metadata_Author().fromJsonString(jsonString, options);
  }

  static equals(a: Preset_Metadata_Author | PlainMessage<Preset_Metadata_Author> | undefined, b: Preset_Metadata_Author | PlainMessage<Preset_Metadata_Author> | undefined): boolean {
    return proto3.util.equals(Preset_Metadata_Author, a, b);
  }
}

/**
 * response only, preset upload 할 때는 이 필드를 nil 로 비워두세요.
 *
 * @generated from message retrica.Preset.ActionTimestamps
 */
export class Preset_ActionTimestamps extends Message<Preset_ActionTimestamps> {
  /**
   * all default 0 -> 값이 0 이면 해당 action 이 없었다는 의미입니다.
   *
   * '/a2/ac/likePreset', '/a2/ac/favoritePresets' 요청의 응답일 때만 이 필드에 값이 채워집니다.
   *
   * @generated from field: int64 liked_at = 1;
   */
  likedAt = protoInt64.zero;

  constructor(data?: PartialMessage<Preset_ActionTimestamps>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.Preset.ActionTimestamps";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "liked_at", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Preset_ActionTimestamps {
    return new Preset_ActionTimestamps().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Preset_ActionTimestamps {
    return new Preset_ActionTimestamps().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Preset_ActionTimestamps {
    return new Preset_ActionTimestamps().fromJsonString(jsonString, options);
  }

  static equals(a: Preset_ActionTimestamps | PlainMessage<Preset_ActionTimestamps> | undefined, b: Preset_ActionTimestamps | PlainMessage<Preset_ActionTimestamps> | undefined): boolean {
    return proto3.util.equals(Preset_ActionTimestamps, a, b);
  }
}

/**
 * @generated from message retrica.LookUpTable
 */
export class LookUpTable extends Message<LookUpTable> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: string lut_url = 3;
   */
  lutUrl = "";

  /**
   * @generated from field: string preview_url = 4;
   */
  previewUrl = "";

  /**
   * @generated from field: string origin_url = 5;
   */
  originUrl = "";

  /**
   * @generated from field: retrica.Friend author = 6;
   */
  author?: Friend;

  constructor(data?: PartialMessage<LookUpTable>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.LookUpTable";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "lut_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "preview_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "origin_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "author", kind: "message", T: Friend },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LookUpTable {
    return new LookUpTable().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LookUpTable {
    return new LookUpTable().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LookUpTable {
    return new LookUpTable().fromJsonString(jsonString, options);
  }

  static equals(a: LookUpTable | PlainMessage<LookUpTable> | undefined, b: LookUpTable | PlainMessage<LookUpTable> | undefined): boolean {
    return proto3.util.equals(LookUpTable, a, b);
  }
}

/**
 * @generated from message retrica.Event
 */
export class Event extends Message<Event> {
  constructor(data?: PartialMessage<Event>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.Event";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Event {
    return new Event().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Event {
    return new Event().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Event {
    return new Event().fromJsonString(jsonString, options);
  }

  static equals(a: Event | PlainMessage<Event> | undefined, b: Event | PlainMessage<Event> | undefined): boolean {
    return proto3.util.equals(Event, a, b);
  }
}

/**
 * @generated from message retrica.ProfileContent
 */
export class ProfileContent extends Message<ProfileContent> {
  /**
   * 프로필 ID
   *
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * 순서
   *
   * @generated from field: int32 priority = 2;
   */
  priority = 0;

  /**
   * 프로필 정보
   *
   * @generated from field: retrica.Content content = 3;
   */
  content?: Content;

  constructor(data?: PartialMessage<ProfileContent>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.ProfileContent";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "priority", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "content", kind: "message", T: Content },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProfileContent {
    return new ProfileContent().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProfileContent {
    return new ProfileContent().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProfileContent {
    return new ProfileContent().fromJsonString(jsonString, options);
  }

  static equals(a: ProfileContent | PlainMessage<ProfileContent> | undefined, b: ProfileContent | PlainMessage<ProfileContent> | undefined): boolean {
    return proto3.util.equals(ProfileContent, a, b);
  }
}

/**
 * @generated from message retrica.ProfileWrap
 */
export class ProfileWrap extends Message<ProfileWrap> {
  /**
   * @generated from field: retrica.User user = 1;
   */
  user?: User;

  /**
   * @generated from field: repeated retrica.ProfileContent contents = 2;
   */
  contents: ProfileContent[] = [];

  constructor(data?: PartialMessage<ProfileWrap>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.ProfileWrap";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user", kind: "message", T: User },
    { no: 2, name: "contents", kind: "message", T: ProfileContent, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProfileWrap {
    return new ProfileWrap().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProfileWrap {
    return new ProfileWrap().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProfileWrap {
    return new ProfileWrap().fromJsonString(jsonString, options);
  }

  static equals(a: ProfileWrap | PlainMessage<ProfileWrap> | undefined, b: ProfileWrap | PlainMessage<ProfileWrap> | undefined): boolean {
    return proto3.util.equals(ProfileWrap, a, b);
  }
}

/**
 * @generated from message retrica.ChannelContent
 */
export class ChannelContent extends Message<ChannelContent> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string user_id = 2;
   */
  userId = "";

  /**
   * @generated from field: retrica.ChannelContentType type = 3;
   */
  type = ChannelContentType.CCT_NONE;

  /**
   * @generated from field: string url = 4;
   */
  url = "";

  /**
   * @generated from field: map<string, string> url_headers = 5;
   */
  urlHeaders: { [key: string]: string } = {};

  /**
   * @generated from field: string thumb = 6;
   */
  thumb = "";

  /**
   * @generated from field: map<string, string> thumb_headers = 7;
   */
  thumbHeaders: { [key: string]: string } = {};

  /**
   * @generated from field: int32 comment_count = 8;
   */
  commentCount = 0;

  /**
   * @generated from field: repeated retrica.ChannelContent.Comment comments = 9;
   */
  comments: ChannelContent_Comment[] = [];

  /**
   * @generated from field: int32 width = 10;
   */
  width = 0;

  /**
   * @generated from field: int32 height = 11;
   */
  height = 0;

  /**
   * @generated from field: string created_at = 12;
   */
  createdAt = "";

  /**
   * @generated from field: retrica.Unread unread = 13;
   */
  unread?: Unread;

  constructor(data?: PartialMessage<ChannelContent>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.ChannelContent";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "type", kind: "enum", T: proto3.getEnumType(ChannelContentType) },
    { no: 4, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "url_headers", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
    { no: 6, name: "thumb", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "thumb_headers", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
    { no: 8, name: "comment_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 9, name: "comments", kind: "message", T: ChannelContent_Comment, repeated: true },
    { no: 10, name: "width", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 11, name: "height", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 12, name: "created_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "unread", kind: "message", T: Unread },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ChannelContent {
    return new ChannelContent().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ChannelContent {
    return new ChannelContent().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ChannelContent {
    return new ChannelContent().fromJsonString(jsonString, options);
  }

  static equals(a: ChannelContent | PlainMessage<ChannelContent> | undefined, b: ChannelContent | PlainMessage<ChannelContent> | undefined): boolean {
    return proto3.util.equals(ChannelContent, a, b);
  }
}

/**
 * @generated from message retrica.ChannelContent.Comment
 */
export class ChannelContent_Comment extends Message<ChannelContent_Comment> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string user_id = 2;
   */
  userId = "";

  /**
   * @generated from field: string msg = 3;
   */
  msg = "";

  /**
   * @generated from field: string created_at = 4;
   */
  createdAt = "";

  constructor(data?: PartialMessage<ChannelContent_Comment>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.ChannelContent.Comment";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "created_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ChannelContent_Comment {
    return new ChannelContent_Comment().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ChannelContent_Comment {
    return new ChannelContent_Comment().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ChannelContent_Comment {
    return new ChannelContent_Comment().fromJsonString(jsonString, options);
  }

  static equals(a: ChannelContent_Comment | PlainMessage<ChannelContent_Comment> | undefined, b: ChannelContent_Comment | PlainMessage<ChannelContent_Comment> | undefined): boolean {
    return proto3.util.equals(ChannelContent_Comment, a, b);
  }
}

/**
 * @generated from message retrica.Notification
 */
export class Notification extends Message<Notification> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string channel_id = 2;
   */
  channelId = "";

  /**
   * @generated from field: string content_id = 3;
   */
  contentId = "";

  /**
   * @generated from field: string comment_id = 4;
   */
  commentId = "";

  /**
   * @generated from field: retrica.User user = 5;
   */
  user?: User;

  /**
   * @generated from field: string thumb = 6;
   */
  thumb = "";

  /**
   * @generated from field: map<string, string> thumb_headers = 7;
   */
  thumbHeaders: { [key: string]: string } = {};

  /**
   * @generated from field: retrica.NotificationType type = 8;
   */
  type = NotificationType.NT_NONE;

  /**
   * @generated from field: string created_at = 9;
   */
  createdAt = "";

  constructor(data?: PartialMessage<Notification>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.Notification";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "channel_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "content_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "comment_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "user", kind: "message", T: User },
    { no: 6, name: "thumb", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "thumb_headers", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
    { no: 8, name: "type", kind: "enum", T: proto3.getEnumType(NotificationType) },
    { no: 9, name: "created_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Notification {
    return new Notification().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Notification {
    return new Notification().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Notification {
    return new Notification().fromJsonString(jsonString, options);
  }

  static equals(a: Notification | PlainMessage<Notification> | undefined, b: Notification | PlainMessage<Notification> | undefined): boolean {
    return proto3.util.equals(Notification, a, b);
  }
}

/**
 * @generated from message retrica.FriendsWrap
 */
export class FriendsWrap extends Message<FriendsWrap> {
  /**
   * @generated from field: int64 friend_revision = 1;
   */
  friendRevision = protoInt64.zero;

  /**
   * @generated from field: repeated retrica.Friend friends = 2;
   */
  friends: Friend[] = [];

  /**
   * @generated from field: int64 recommend_revision = 3;
   */
  recommendRevision = protoInt64.zero;

  /**
   * @generated from field: repeated retrica.Friend recommends = 4;
   */
  recommends: Friend[] = [];

  /**
   * @generated from field: int64 added_me_revision = 5;
   */
  addedMeRevision = protoInt64.zero;

  /**
   * @generated from field: repeated retrica.Friend added_mes = 6;
   */
  addedMes: Friend[] = [];

  /**
   * @generated from field: int64 block_revision = 7;
   */
  blockRevision = protoInt64.zero;

  /**
   * @generated from field: repeated retrica.Friend blocks = 8;
   */
  blocks: Friend[] = [];

  constructor(data?: PartialMessage<FriendsWrap>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.FriendsWrap";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "friend_revision", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "friends", kind: "message", T: Friend, repeated: true },
    { no: 3, name: "recommend_revision", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "recommends", kind: "message", T: Friend, repeated: true },
    { no: 5, name: "added_me_revision", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 6, name: "added_mes", kind: "message", T: Friend, repeated: true },
    { no: 7, name: "block_revision", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 8, name: "blocks", kind: "message", T: Friend, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FriendsWrap {
    return new FriendsWrap().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FriendsWrap {
    return new FriendsWrap().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FriendsWrap {
    return new FriendsWrap().fromJsonString(jsonString, options);
  }

  static equals(a: FriendsWrap | PlainMessage<FriendsWrap> | undefined, b: FriendsWrap | PlainMessage<FriendsWrap> | undefined): boolean {
    return proto3.util.equals(FriendsWrap, a, b);
  }
}

/**
 * @generated from message retrica.Channel
 */
export class Channel extends Message<Channel> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string channel_name = 2;
   */
  channelName = "";

  /**
   * @generated from field: string channel_image_url = 3;
   */
  channelImageUrl = "";

  /**
   * @generated from field: retrica.ChannelType type = 4;
   */
  type = ChannelType.CT_NONE;

  /**
   * @generated from field: int64 image_count = 5;
   */
  imageCount = protoInt64.zero;

  /**
   * @generated from field: int64 video_count = 6;
   */
  videoCount = protoInt64.zero;

  /**
   * @generated from field: bool push = 7;
   */
  push = false;

  /**
   * @generated from field: repeated retrica.User users = 8;
   */
  users: User[] = [];

  /**
   * @generated from field: string updated_at = 9;
   */
  updatedAt = "";

  /**
   * @generated from field: int64 comment_count = 10;
   */
  commentCount = protoInt64.zero;

  /**
   * @generated from field: retrica.Unread unread = 11;
   */
  unread?: Unread;

  constructor(data?: PartialMessage<Channel>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.Channel";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "channel_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "channel_image_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "type", kind: "enum", T: proto3.getEnumType(ChannelType) },
    { no: 5, name: "image_count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 6, name: "video_count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 7, name: "push", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: "users", kind: "message", T: User, repeated: true },
    { no: 9, name: "updated_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "comment_count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 11, name: "unread", kind: "message", T: Unread },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Channel {
    return new Channel().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Channel {
    return new Channel().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Channel {
    return new Channel().fromJsonString(jsonString, options);
  }

  static equals(a: Channel | PlainMessage<Channel> | undefined, b: Channel | PlainMessage<Channel> | undefined): boolean {
    return proto3.util.equals(Channel, a, b);
  }
}

/**
 * @generated from message retrica.Comment
 */
export class Comment extends Message<Comment> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string user_id = 2;
   */
  userId = "";

  /**
   * @generated from field: string msg = 3;
   */
  msg = "";

  /**
   * @generated from field: string created_at = 4;
   */
  createdAt = "";

  /**
   * @generated from field: retrica.User owner = 5;
   */
  owner?: User;

  constructor(data?: PartialMessage<Comment>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.Comment";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "created_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "owner", kind: "message", T: User },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Comment {
    return new Comment().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Comment {
    return new Comment().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Comment {
    return new Comment().fromJsonString(jsonString, options);
  }

  static equals(a: Comment | PlainMessage<Comment> | undefined, b: Comment | PlainMessage<Comment> | undefined): boolean {
    return proto3.util.equals(Comment, a, b);
  }
}

/**
 * @generated from message retrica.ChannelContentV2
 */
export class ChannelContentV2 extends Message<ChannelContentV2> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: retrica.User owner = 2;
   */
  owner?: User;

  /**
   * @generated from field: retrica.Channel channel = 3;
   */
  channel?: Channel;

  /**
   * @generated from field: retrica.Content content = 4;
   */
  content?: Content;

  /**
   * @generated from field: repeated retrica.Comment comments = 5;
   */
  comments: Comment[] = [];

  /**
   * @generated from field: retrica.Unread unread = 6;
   */
  unread?: Unread;

  /**
   * @generated from field: int32 total_comment_count = 7;
   */
  totalCommentCount = 0;

  constructor(data?: PartialMessage<ChannelContentV2>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.ChannelContentV2";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "owner", kind: "message", T: User },
    { no: 3, name: "channel", kind: "message", T: Channel },
    { no: 4, name: "content", kind: "message", T: Content },
    { no: 5, name: "comments", kind: "message", T: Comment, repeated: true },
    { no: 6, name: "unread", kind: "message", T: Unread },
    { no: 7, name: "total_comment_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ChannelContentV2 {
    return new ChannelContentV2().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ChannelContentV2 {
    return new ChannelContentV2().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ChannelContentV2 {
    return new ChannelContentV2().fromJsonString(jsonString, options);
  }

  static equals(a: ChannelContentV2 | PlainMessage<ChannelContentV2> | undefined, b: ChannelContentV2 | PlainMessage<ChannelContentV2> | undefined): boolean {
    return proto3.util.equals(ChannelContentV2, a, b);
  }
}

/**
 * @generated from message retrica.SelfieNetwork
 */
export class SelfieNetwork extends Message<SelfieNetwork> {
  constructor(data?: PartialMessage<SelfieNetwork>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.SelfieNetwork";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SelfieNetwork {
    return new SelfieNetwork().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SelfieNetwork {
    return new SelfieNetwork().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SelfieNetwork {
    return new SelfieNetwork().fromJsonString(jsonString, options);
  }

  static equals(a: SelfieNetwork | PlainMessage<SelfieNetwork> | undefined, b: SelfieNetwork | PlainMessage<SelfieNetwork> | undefined): boolean {
    return proto3.util.equals(SelfieNetwork, a, b);
  }
}

/**
 * @generated from message retrica.SelfieNetwork.SquadContainer
 */
export class SelfieNetwork_SquadContainer extends Message<SelfieNetwork_SquadContainer> {
  constructor(data?: PartialMessage<SelfieNetwork_SquadContainer>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.SelfieNetwork.SquadContainer";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SelfieNetwork_SquadContainer {
    return new SelfieNetwork_SquadContainer().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SelfieNetwork_SquadContainer {
    return new SelfieNetwork_SquadContainer().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SelfieNetwork_SquadContainer {
    return new SelfieNetwork_SquadContainer().fromJsonString(jsonString, options);
  }

  static equals(a: SelfieNetwork_SquadContainer | PlainMessage<SelfieNetwork_SquadContainer> | undefined, b: SelfieNetwork_SquadContainer | PlainMessage<SelfieNetwork_SquadContainer> | undefined): boolean {
    return proto3.util.equals(SelfieNetwork_SquadContainer, a, b);
  }
}

/**
 * @generated from message retrica.SelfieNetwork.SquadContainer.Squad
 */
export class SelfieNetwork_SquadContainer_Squad extends Message<SelfieNetwork_SquadContainer_Squad> {
  /**
   * 이름
   *
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * display 이름
   *
   * @generated from field: string display_name = 2;
   */
  displayName = "";

  /**
   * 색상 ex) #ABCDEF
   *
   * @generated from field: string theme = 3;
   */
  theme = "";

  /**
   * 참가자수
   *
   * @generated from field: int32 joins = 4;
   */
  joins = 0;

  /**
   * protocol.SquadModeType list 권한
   *
   * @generated from field: repeated int32 modes = 5;
   */
  modes: number[] = [];

  /**
   * thumbnails 3개
   *
   * @generated from field: repeated string thumbnail_urls = 6;
   */
  thumbnailUrls: string[] = [];

  /**
   * 생성
   *
   * @generated from field: int64 created_at = 7;
   */
  createdAt = protoInt64.zero;

  /**
   * 업데이트
   *
   * @generated from field: int64 updated_at = 8;
   */
  updatedAt = protoInt64.zero;

  /**
   * 현재 참가중인지 확인
   *
   * @generated from field: bool is_joining = 9;
   */
  isJoining = false;

  constructor(data?: PartialMessage<SelfieNetwork_SquadContainer_Squad>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.SelfieNetwork.SquadContainer.Squad";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "display_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "theme", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "joins", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "modes", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 6, name: "thumbnail_urls", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 7, name: "created_at", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 8, name: "updated_at", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 9, name: "is_joining", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SelfieNetwork_SquadContainer_Squad {
    return new SelfieNetwork_SquadContainer_Squad().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SelfieNetwork_SquadContainer_Squad {
    return new SelfieNetwork_SquadContainer_Squad().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SelfieNetwork_SquadContainer_Squad {
    return new SelfieNetwork_SquadContainer_Squad().fromJsonString(jsonString, options);
  }

  static equals(a: SelfieNetwork_SquadContainer_Squad | PlainMessage<SelfieNetwork_SquadContainer_Squad> | undefined, b: SelfieNetwork_SquadContainer_Squad | PlainMessage<SelfieNetwork_SquadContainer_Squad> | undefined): boolean {
    return proto3.util.equals(SelfieNetwork_SquadContainer_Squad, a, b);
  }
}

/**
 * @generated from message retrica.SelfieNetwork.SquadContainer.Crew
 */
export class SelfieNetwork_SquadContainer_Crew extends Message<SelfieNetwork_SquadContainer_Crew> {
  /**
   * 친구
   *
   * @generated from field: retrica.Friend friend = 1;
   */
  friend?: Friend;

  /**
   * 참가시간
   *
   * @generated from field: int64 joined_at = 2;
   */
  joinedAt = protoInt64.zero;

  constructor(data?: PartialMessage<SelfieNetwork_SquadContainer_Crew>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.SelfieNetwork.SquadContainer.Crew";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "friend", kind: "message", T: Friend },
    { no: 2, name: "joined_at", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SelfieNetwork_SquadContainer_Crew {
    return new SelfieNetwork_SquadContainer_Crew().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SelfieNetwork_SquadContainer_Crew {
    return new SelfieNetwork_SquadContainer_Crew().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SelfieNetwork_SquadContainer_Crew {
    return new SelfieNetwork_SquadContainer_Crew().fromJsonString(jsonString, options);
  }

  static equals(a: SelfieNetwork_SquadContainer_Crew | PlainMessage<SelfieNetwork_SquadContainer_Crew> | undefined, b: SelfieNetwork_SquadContainer_Crew | PlainMessage<SelfieNetwork_SquadContainer_Crew> | undefined): boolean {
    return proto3.util.equals(SelfieNetwork_SquadContainer_Crew, a, b);
  }
}

/**
 * @generated from message retrica.SelfieNetwork.Activity
 */
export class SelfieNetwork_Activity extends Message<SelfieNetwork_Activity> {
  /**
   * 필수
   *
   * 타입
   *
   * @generated from field: retrica.ActivityType type = 1;
   */
  type = ActivityType.AT_NONE;

  /**
   *  메시지
   *
   * @generated from field: string message = 2;
   */
  message = "";

  /**
   * DeepLink
   *
   * @generated from field: string deeplink = 3;
   */
  deeplink = "";

  /**
   * 시간
   *
   * @generated from field: int64 created_at = 4;
   */
  createdAt = protoInt64.zero;

  /**
   * 보낸이(행위자)
   *
   * @generated from field: repeated retrica.SelfieNetwork.Activity.Actor actors = 5;
   */
  actors: SelfieNetwork_Activity_Actor[] = [];

  /**
   * 선택
   *
   * @generated from field: retrica.SelfieNetwork.Activity.Option option = 6;
   */
  option?: SelfieNetwork_Activity_Option;

  constructor(data?: PartialMessage<SelfieNetwork_Activity>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.SelfieNetwork.Activity";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "type", kind: "enum", T: proto3.getEnumType(ActivityType) },
    { no: 2, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "deeplink", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "created_at", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "actors", kind: "message", T: SelfieNetwork_Activity_Actor, repeated: true },
    { no: 6, name: "option", kind: "message", T: SelfieNetwork_Activity_Option },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SelfieNetwork_Activity {
    return new SelfieNetwork_Activity().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SelfieNetwork_Activity {
    return new SelfieNetwork_Activity().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SelfieNetwork_Activity {
    return new SelfieNetwork_Activity().fromJsonString(jsonString, options);
  }

  static equals(a: SelfieNetwork_Activity | PlainMessage<SelfieNetwork_Activity> | undefined, b: SelfieNetwork_Activity | PlainMessage<SelfieNetwork_Activity> | undefined): boolean {
    return proto3.util.equals(SelfieNetwork_Activity, a, b);
  }
}

/**
 * @generated from message retrica.SelfieNetwork.Activity.Actor
 */
export class SelfieNetwork_Activity_Actor extends Message<SelfieNetwork_Activity_Actor> {
  /**
   * @generated from field: retrica.Friend friend = 1;
   */
  friend?: Friend;

  /**
   * @generated from field: string deeplink = 2;
   */
  deeplink = "";

  constructor(data?: PartialMessage<SelfieNetwork_Activity_Actor>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.SelfieNetwork.Activity.Actor";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "friend", kind: "message", T: Friend },
    { no: 2, name: "deeplink", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SelfieNetwork_Activity_Actor {
    return new SelfieNetwork_Activity_Actor().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SelfieNetwork_Activity_Actor {
    return new SelfieNetwork_Activity_Actor().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SelfieNetwork_Activity_Actor {
    return new SelfieNetwork_Activity_Actor().fromJsonString(jsonString, options);
  }

  static equals(a: SelfieNetwork_Activity_Actor | PlainMessage<SelfieNetwork_Activity_Actor> | undefined, b: SelfieNetwork_Activity_Actor | PlainMessage<SelfieNetwork_Activity_Actor> | undefined): boolean {
    return proto3.util.equals(SelfieNetwork_Activity_Actor, a, b);
  }
}

/**
 * @generated from message retrica.SelfieNetwork.Activity.Option
 */
export class SelfieNetwork_Activity_Option extends Message<SelfieNetwork_Activity_Option> {
  /**
   * @generated from field: retrica.SelfieNetwork.Selfie selfie = 1;
   */
  selfie?: SelfieNetwork_Selfie;

  /**
   * @generated from field: retrica.SelfieNetwork.SquadContainer.Squad squad = 2;
   */
  squad?: SelfieNetwork_SquadContainer_Squad;

  constructor(data?: PartialMessage<SelfieNetwork_Activity_Option>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.SelfieNetwork.Activity.Option";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "selfie", kind: "message", T: SelfieNetwork_Selfie },
    { no: 2, name: "squad", kind: "message", T: SelfieNetwork_SquadContainer_Squad },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SelfieNetwork_Activity_Option {
    return new SelfieNetwork_Activity_Option().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SelfieNetwork_Activity_Option {
    return new SelfieNetwork_Activity_Option().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SelfieNetwork_Activity_Option {
    return new SelfieNetwork_Activity_Option().fromJsonString(jsonString, options);
  }

  static equals(a: SelfieNetwork_Activity_Option | PlainMessage<SelfieNetwork_Activity_Option> | undefined, b: SelfieNetwork_Activity_Option | PlainMessage<SelfieNetwork_Activity_Option> | undefined): boolean {
    return proto3.util.equals(SelfieNetwork_Activity_Option, a, b);
  }
}

/**
 * @generated from message retrica.SelfieNetwork.HashTag
 */
export class SelfieNetwork_HashTag extends Message<SelfieNetwork_HashTag> {
  /**
   * (Request, Response 사용)
   *
   * @generated from field: retrica.HashTagType type = 1;
   */
  type = HashTagType.HTT_NONE;

  /**
   * ( Request, Response 사용)
   *
   * @generated from field: retrica.SelfieNetwork.HashTag.Tag tag = 2;
   */
  tag?: SelfieNetwork_HashTag_Tag;

  constructor(data?: PartialMessage<SelfieNetwork_HashTag>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.SelfieNetwork.HashTag";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "type", kind: "enum", T: proto3.getEnumType(HashTagType) },
    { no: 2, name: "tag", kind: "message", T: SelfieNetwork_HashTag_Tag },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SelfieNetwork_HashTag {
    return new SelfieNetwork_HashTag().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SelfieNetwork_HashTag {
    return new SelfieNetwork_HashTag().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SelfieNetwork_HashTag {
    return new SelfieNetwork_HashTag().fromJsonString(jsonString, options);
  }

  static equals(a: SelfieNetwork_HashTag | PlainMessage<SelfieNetwork_HashTag> | undefined, b: SelfieNetwork_HashTag | PlainMessage<SelfieNetwork_HashTag> | undefined): boolean {
    return proto3.util.equals(SelfieNetwork_HashTag, a, b);
  }
}

/**
 * @generated from message retrica.SelfieNetwork.HashTag.Tag
 */
export class SelfieNetwork_HashTag_Tag extends Message<SelfieNetwork_HashTag_Tag> {
  /**
   * 실제 tag (Request, Response)
   *
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * 보여줄형태 tag (Response)
   *
   * @generated from field: string display_name = 2;
   */
  displayName = "";

  /**
   * 색상리스트(Response)
   *
   * @generated from field: string colors = 3;
   */
  colors = "";

  constructor(data?: PartialMessage<SelfieNetwork_HashTag_Tag>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.SelfieNetwork.HashTag.Tag";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "display_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "colors", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SelfieNetwork_HashTag_Tag {
    return new SelfieNetwork_HashTag_Tag().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SelfieNetwork_HashTag_Tag {
    return new SelfieNetwork_HashTag_Tag().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SelfieNetwork_HashTag_Tag {
    return new SelfieNetwork_HashTag_Tag().fromJsonString(jsonString, options);
  }

  static equals(a: SelfieNetwork_HashTag_Tag | PlainMessage<SelfieNetwork_HashTag_Tag> | undefined, b: SelfieNetwork_HashTag_Tag | PlainMessage<SelfieNetwork_HashTag_Tag> | undefined): boolean {
    return proto3.util.equals(SelfieNetwork_HashTag_Tag, a, b);
  }
}

/**
 * @generated from message retrica.SelfieNetwork.Decorator
 */
export class SelfieNetwork_Decorator extends Message<SelfieNetwork_Decorator> {
  /**
   * @generated from field: retrica.SelfieNetwork.Decorator.Rect rect = 1;
   */
  rect?: SelfieNetwork_Decorator_Rect;

  /**
   * @generated from field: int32 priority = 2;
   */
  priority = 0;

  /**
   * @generated from field: int32 degree = 3;
   */
  degree = 0;

  /**
   * @generated from field: repeated retrica.SelfieNetwork.Decorator.Mention mentions = 4;
   */
  mentions: SelfieNetwork_Decorator_Mention[] = [];

  constructor(data?: PartialMessage<SelfieNetwork_Decorator>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.SelfieNetwork.Decorator";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "rect", kind: "message", T: SelfieNetwork_Decorator_Rect },
    { no: 2, name: "priority", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "degree", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "mentions", kind: "message", T: SelfieNetwork_Decorator_Mention, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SelfieNetwork_Decorator {
    return new SelfieNetwork_Decorator().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SelfieNetwork_Decorator {
    return new SelfieNetwork_Decorator().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SelfieNetwork_Decorator {
    return new SelfieNetwork_Decorator().fromJsonString(jsonString, options);
  }

  static equals(a: SelfieNetwork_Decorator | PlainMessage<SelfieNetwork_Decorator> | undefined, b: SelfieNetwork_Decorator | PlainMessage<SelfieNetwork_Decorator> | undefined): boolean {
    return proto3.util.equals(SelfieNetwork_Decorator, a, b);
  }
}

/**
 * @generated from message retrica.SelfieNetwork.Decorator.Rect
 */
export class SelfieNetwork_Decorator_Rect extends Message<SelfieNetwork_Decorator_Rect> {
  /**
   * 0 사이 1 <비율> : left top x
   *
   * @generated from field: float x = 1;
   */
  x = 0;

  /**
   * 0 사이 1 <비율> : left top y
   *
   * @generated from field: float y = 2;
   */
  y = 0;

  /**
   * 0 사이 1 <비율> : width
   *
   * @generated from field: float width = 3;
   */
  width = 0;

  /**
   * 0 사이 1 <비율> : height
   *
   * @generated from field: float height = 4;
   */
  height = 0;

  constructor(data?: PartialMessage<SelfieNetwork_Decorator_Rect>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.SelfieNetwork.Decorator.Rect";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "x", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 2, name: "y", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 3, name: "width", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 4, name: "height", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SelfieNetwork_Decorator_Rect {
    return new SelfieNetwork_Decorator_Rect().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SelfieNetwork_Decorator_Rect {
    return new SelfieNetwork_Decorator_Rect().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SelfieNetwork_Decorator_Rect {
    return new SelfieNetwork_Decorator_Rect().fromJsonString(jsonString, options);
  }

  static equals(a: SelfieNetwork_Decorator_Rect | PlainMessage<SelfieNetwork_Decorator_Rect> | undefined, b: SelfieNetwork_Decorator_Rect | PlainMessage<SelfieNetwork_Decorator_Rect> | undefined): boolean {
    return proto3.util.equals(SelfieNetwork_Decorator_Rect, a, b);
  }
}

/**
 * @generated from message retrica.SelfieNetwork.Decorator.Mention
 */
export class SelfieNetwork_Decorator_Mention extends Message<SelfieNetwork_Decorator_Mention> {
  /**
   * @generated from field: string user_id = 1;
   */
  userId = "";

  /**
   * @generated from field: retrica.SelfieNetwork.Decorator.Rect rect = 2;
   */
  rect?: SelfieNetwork_Decorator_Rect;

  constructor(data?: PartialMessage<SelfieNetwork_Decorator_Mention>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.SelfieNetwork.Decorator.Mention";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "rect", kind: "message", T: SelfieNetwork_Decorator_Rect },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SelfieNetwork_Decorator_Mention {
    return new SelfieNetwork_Decorator_Mention().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SelfieNetwork_Decorator_Mention {
    return new SelfieNetwork_Decorator_Mention().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SelfieNetwork_Decorator_Mention {
    return new SelfieNetwork_Decorator_Mention().fromJsonString(jsonString, options);
  }

  static equals(a: SelfieNetwork_Decorator_Mention | PlainMessage<SelfieNetwork_Decorator_Mention> | undefined, b: SelfieNetwork_Decorator_Mention | PlainMessage<SelfieNetwork_Decorator_Mention> | undefined): boolean {
    return proto3.util.equals(SelfieNetwork_Decorator_Mention, a, b);
  }
}

/**
 * @generated from message retrica.SelfieNetwork.Selfie
 */
export class SelfieNetwork_Selfie extends Message<SelfieNetwork_Selfie> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string user_id = 2;
   */
  userId = "";

  /**
   * @generated from field: retrica.Content content = 3;
   */
  content?: Content;

  /**
   * @generated from field: int32 view_count = 4;
   */
  viewCount = 0;

  /**
   * @generated from field: int32 like_count = 5;
   */
  likeCount = 0;

  /**
   * @generated from field: int64 created_at = 6;
   */
  createdAt = protoInt64.zero;

  /**
   * @generated from field: int64 updated_at = 7;
   */
  updatedAt = protoInt64.zero;

  /**
   * @generated from field: repeated retrica.SelfieNetwork.HashTag tags = 8;
   */
  tags: SelfieNetwork_HashTag[] = [];

  /**
   * @generated from field: repeated retrica.SelfieNetwork.Decorator decorators = 9;
   */
  decorators: SelfieNetwork_Decorator[] = [];

  constructor(data?: PartialMessage<SelfieNetwork_Selfie>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.SelfieNetwork.Selfie";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "content", kind: "message", T: Content },
    { no: 4, name: "view_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "like_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "created_at", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 7, name: "updated_at", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 8, name: "tags", kind: "message", T: SelfieNetwork_HashTag, repeated: true },
    { no: 9, name: "decorators", kind: "message", T: SelfieNetwork_Decorator, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SelfieNetwork_Selfie {
    return new SelfieNetwork_Selfie().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SelfieNetwork_Selfie {
    return new SelfieNetwork_Selfie().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SelfieNetwork_Selfie {
    return new SelfieNetwork_Selfie().fromJsonString(jsonString, options);
  }

  static equals(a: SelfieNetwork_Selfie | PlainMessage<SelfieNetwork_Selfie> | undefined, b: SelfieNetwork_Selfie | PlainMessage<SelfieNetwork_Selfie> | undefined): boolean {
    return proto3.util.equals(SelfieNetwork_Selfie, a, b);
  }
}

/**
 * @generated from message retrica.SelfieNetwork.SelfieWrap
 */
export class SelfieNetwork_SelfieWrap extends Message<SelfieNetwork_SelfieWrap> {
  /**
   * @generated from field: string user_id = 1;
   */
  userId = "";

  /**
   * @generated from field: repeated retrica.SelfieNetwork.Selfie selfies = 2;
   */
  selfies: SelfieNetwork_Selfie[] = [];

  /**
   * @generated from field: int64 updated_at = 3;
   */
  updatedAt = protoInt64.zero;

  constructor(data?: PartialMessage<SelfieNetwork_SelfieWrap>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.SelfieNetwork.SelfieWrap";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "selfies", kind: "message", T: SelfieNetwork_Selfie, repeated: true },
    { no: 3, name: "updated_at", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SelfieNetwork_SelfieWrap {
    return new SelfieNetwork_SelfieWrap().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SelfieNetwork_SelfieWrap {
    return new SelfieNetwork_SelfieWrap().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SelfieNetwork_SelfieWrap {
    return new SelfieNetwork_SelfieWrap().fromJsonString(jsonString, options);
  }

  static equals(a: SelfieNetwork_SelfieWrap | PlainMessage<SelfieNetwork_SelfieWrap> | undefined, b: SelfieNetwork_SelfieWrap | PlainMessage<SelfieNetwork_SelfieWrap> | undefined): boolean {
    return proto3.util.equals(SelfieNetwork_SelfieWrap, a, b);
  }
}

/**
 * @generated from message retrica.SelfieNetwork.SelfiePage
 */
export class SelfieNetwork_SelfiePage extends Message<SelfieNetwork_SelfiePage> {
  /**
   * @generated from field: string user_id = 1;
   */
  userId = "";

  /**
   * @generated from field: repeated retrica.SelfieNetwork.Selfie selfies = 2;
   */
  selfies: SelfieNetwork_Selfie[] = [];

  /**
   * @generated from field: int32 total_selfie_count = 3;
   */
  totalSelfieCount = 0;

  /**
   * @generated from field: int32 accumulate_view_count = 4;
   */
  accumulateViewCount = 0;

  /**
   * @generated from field: int32 accumulate_like_count = 5;
   */
  accumulateLikeCount = 0;

  /**
   * 최대 상위 10개만 내려준다.
   *
   * @generated from field: map<string, int32> accumulate_likes_map = 6;
   */
  accumulateLikesMap: { [key: string]: number } = {};

  constructor(data?: PartialMessage<SelfieNetwork_SelfiePage>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.SelfieNetwork.SelfiePage";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "selfies", kind: "message", T: SelfieNetwork_Selfie, repeated: true },
    { no: 3, name: "total_selfie_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "accumulate_view_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "accumulate_like_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "accumulate_likes_map", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 5 /* ScalarType.INT32 */} },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SelfieNetwork_SelfiePage {
    return new SelfieNetwork_SelfiePage().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SelfieNetwork_SelfiePage {
    return new SelfieNetwork_SelfiePage().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SelfieNetwork_SelfiePage {
    return new SelfieNetwork_SelfiePage().fromJsonString(jsonString, options);
  }

  static equals(a: SelfieNetwork_SelfiePage | PlainMessage<SelfieNetwork_SelfiePage> | undefined, b: SelfieNetwork_SelfiePage | PlainMessage<SelfieNetwork_SelfiePage> | undefined): boolean {
    return proto3.util.equals(SelfieNetwork_SelfiePage, a, b);
  }
}

/**
 * @generated from message retrica.SelfieNetwork.HashTagWrap
 */
export class SelfieNetwork_HashTagWrap extends Message<SelfieNetwork_HashTagWrap> {
  /**
   * @generated from field: retrica.SelfieNetwork.HashTag tag = 1;
   */
  tag?: SelfieNetwork_HashTag;

  /**
   * @generated from field: repeated retrica.SelfieNetwork.Selfie selfies = 2;
   */
  selfies: SelfieNetwork_Selfie[] = [];

  constructor(data?: PartialMessage<SelfieNetwork_HashTagWrap>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.SelfieNetwork.HashTagWrap";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "tag", kind: "message", T: SelfieNetwork_HashTag },
    { no: 2, name: "selfies", kind: "message", T: SelfieNetwork_Selfie, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SelfieNetwork_HashTagWrap {
    return new SelfieNetwork_HashTagWrap().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SelfieNetwork_HashTagWrap {
    return new SelfieNetwork_HashTagWrap().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SelfieNetwork_HashTagWrap {
    return new SelfieNetwork_HashTagWrap().fromJsonString(jsonString, options);
  }

  static equals(a: SelfieNetwork_HashTagWrap | PlainMessage<SelfieNetwork_HashTagWrap> | undefined, b: SelfieNetwork_HashTagWrap | PlainMessage<SelfieNetwork_HashTagWrap> | undefined): boolean {
    return proto3.util.equals(SelfieNetwork_HashTagWrap, a, b);
  }
}

/**
 * @generated from message retrica.CloudContent
 */
export class CloudContent extends Message<CloudContent> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: retrica.Content content = 2;
   */
  content?: Content;

  constructor(data?: PartialMessage<CloudContent>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.CloudContent";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "content", kind: "message", T: Content },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CloudContent {
    return new CloudContent().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CloudContent {
    return new CloudContent().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CloudContent {
    return new CloudContent().fromJsonString(jsonString, options);
  }

  static equals(a: CloudContent | PlainMessage<CloudContent> | undefined, b: CloudContent | PlainMessage<CloudContent> | undefined): boolean {
    return proto3.util.equals(CloudContent, a, b);
  }
}

/**
 * @generated from message retrica.Unread
 */
export class Unread extends Message<Unread> {
  /**
   * @generated from field: bool is_new = 1;
   */
  isNew = false;

  /**
   * @generated from field: int32 count = 2;
   */
  count = 0;

  constructor(data?: PartialMessage<Unread>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.Unread";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "is_new", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Unread {
    return new Unread().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Unread {
    return new Unread().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Unread {
    return new Unread().fromJsonString(jsonString, options);
  }

  static equals(a: Unread | PlainMessage<Unread> | undefined, b: Unread | PlainMessage<Unread> | undefined): boolean {
    return proto3.util.equals(Unread, a, b);
  }
}

