// @generated by protoc-gen-es v1.10.0 with parameter "target=ts,import_extension=.ts"
// @generated from file retriver-image-process.proto (package retrica, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { Alignment, BlendType, DateTimeFormat } from "./retriver-enum_pb.ts";

/**
 * @generated from message retrica.ImageProcess
 */
export class ImageProcess extends Message<ImageProcess> {
  /**
   * @generated from field: retrica.ImageProcess.ProcessType type = 1;
   */
  type = ImageProcess_ProcessType.TYPE_NONE;

  /**
   * @generated from oneof retrica.ImageProcess.params
   */
  params: {
    /**
     * @generated from field: retrica.ExposureParams exposure_params = 2;
     */
    value: ExposureParams;
    case: "exposureParams";
  } | {
    /**
     * @generated from field: retrica.BrightnessParams brightness_params = 3;
     */
    value: BrightnessParams;
    case: "brightnessParams";
  } | {
    /**
     * @generated from field: retrica.ContrastParams contrast_params = 4;
     */
    value: ContrastParams;
    case: "contrastParams";
  } | {
    /**
     * @generated from field: retrica.SharpenParams sharpen_params = 5;
     */
    value: SharpenParams;
    case: "sharpenParams";
  } | {
    /**
     * @generated from field: retrica.SaturationParams saturation_params = 6;
     */
    value: SaturationParams;
    case: "saturationParams";
  } | {
    /**
     * @generated from field: retrica.HighlightsParams highlights_params = 7;
     */
    value: HighlightsParams;
    case: "highlightsParams";
  } | {
    /**
     * @generated from field: retrica.ShadowsParams shadows_params = 8;
     */
    value: ShadowsParams;
    case: "shadowsParams";
  } | {
    /**
     * @generated from field: retrica.WhiteBalanceParams white_balance_params = 9;
     */
    value: WhiteBalanceParams;
    case: "whiteBalanceParams";
  } | {
    /**
     * @generated from field: retrica.VignetteParams vignette_params = 10;
     */
    value: VignetteParams;
    case: "vignetteParams";
  } | {
    /**
     * @generated from field: retrica.GrainParams grain_params = 11;
     */
    value: GrainParams;
    case: "grainParams";
  } | {
    /**
     * @generated from field: retrica.LookUpParams look_up_params = 12;
     */
    value: LookUpParams;
    case: "lookUpParams";
  } | {
    /**
     * @generated from field: retrica.FadeParams fade_params = 13;
     */
    value: FadeParams;
    case: "fadeParams";
  } | {
    /**
     * @generated from field: retrica.BlurParams blur_params = 14;
     */
    value: BlurParams;
    case: "blurParams";
  } | {
    /**
     * @generated from field: retrica.HSLParams hsl_params = 15;
     */
    value: HSLParams;
    case: "hslParams";
  } | {
    /**
     * @generated from field: retrica.TextureParams texture_params = 16;
     */
    value: TextureParams;
    case: "textureParams";
  } | {
    /**
     * @generated from field: retrica.StampParams stamp_params = 17;
     */
    value: StampParams;
    case: "stampParams";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<ImageProcess>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.ImageProcess";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "type", kind: "enum", T: proto3.getEnumType(ImageProcess_ProcessType) },
    { no: 2, name: "exposure_params", kind: "message", T: ExposureParams, oneof: "params" },
    { no: 3, name: "brightness_params", kind: "message", T: BrightnessParams, oneof: "params" },
    { no: 4, name: "contrast_params", kind: "message", T: ContrastParams, oneof: "params" },
    { no: 5, name: "sharpen_params", kind: "message", T: SharpenParams, oneof: "params" },
    { no: 6, name: "saturation_params", kind: "message", T: SaturationParams, oneof: "params" },
    { no: 7, name: "highlights_params", kind: "message", T: HighlightsParams, oneof: "params" },
    { no: 8, name: "shadows_params", kind: "message", T: ShadowsParams, oneof: "params" },
    { no: 9, name: "white_balance_params", kind: "message", T: WhiteBalanceParams, oneof: "params" },
    { no: 10, name: "vignette_params", kind: "message", T: VignetteParams, oneof: "params" },
    { no: 11, name: "grain_params", kind: "message", T: GrainParams, oneof: "params" },
    { no: 12, name: "look_up_params", kind: "message", T: LookUpParams, oneof: "params" },
    { no: 13, name: "fade_params", kind: "message", T: FadeParams, oneof: "params" },
    { no: 14, name: "blur_params", kind: "message", T: BlurParams, oneof: "params" },
    { no: 15, name: "hsl_params", kind: "message", T: HSLParams, oneof: "params" },
    { no: 16, name: "texture_params", kind: "message", T: TextureParams, oneof: "params" },
    { no: 17, name: "stamp_params", kind: "message", T: StampParams, oneof: "params" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ImageProcess {
    return new ImageProcess().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ImageProcess {
    return new ImageProcess().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ImageProcess {
    return new ImageProcess().fromJsonString(jsonString, options);
  }

  static equals(a: ImageProcess | PlainMessage<ImageProcess> | undefined, b: ImageProcess | PlainMessage<ImageProcess> | undefined): boolean {
    return proto3.util.equals(ImageProcess, a, b);
  }
}

/**
 * @generated from enum retrica.ImageProcess.ProcessType
 */
export enum ImageProcess_ProcessType {
  /**
   * @generated from enum value: TYPE_NONE = 0;
   */
  TYPE_NONE = 0,

  /**
   * @generated from enum value: TYPE_EXPOSURE = 1;
   */
  TYPE_EXPOSURE = 1,

  /**
   * @generated from enum value: TYPE_BRIGHTNESS = 2;
   */
  TYPE_BRIGHTNESS = 2,

  /**
   * @generated from enum value: TYPE_CONTRAST = 3;
   */
  TYPE_CONTRAST = 3,

  /**
   * @generated from enum value: TYPE_SHARPEN = 4;
   */
  TYPE_SHARPEN = 4,

  /**
   * @generated from enum value: TYPE_SATURATION = 5;
   */
  TYPE_SATURATION = 5,

  /**
   * @generated from enum value: TYPE_HIGHLIGHTS = 6;
   */
  TYPE_HIGHLIGHTS = 6,

  /**
   * @generated from enum value: TYPE_SHADOWS = 7;
   */
  TYPE_SHADOWS = 7,

  /**
   * @generated from enum value: TYPE_WHITE_BALANCE = 8;
   */
  TYPE_WHITE_BALANCE = 8,

  /**
   * @generated from enum value: TYPE_VIGNETTE = 9;
   */
  TYPE_VIGNETTE = 9,

  /**
   * @generated from enum value: TYPE_GRAIN = 10;
   */
  TYPE_GRAIN = 10,

  /**
   * @generated from enum value: TYPE_LOOK_UP = 11;
   */
  TYPE_LOOK_UP = 11,

  /**
   * @generated from enum value: TYPE_FADE = 12;
   */
  TYPE_FADE = 12,

  /**
   * @generated from enum value: TYPE_BLUR = 13;
   */
  TYPE_BLUR = 13,

  /**
   * @generated from enum value: TYPE_HSL = 14;
   */
  TYPE_HSL = 14,

  /**
   * @generated from enum value: TYPE_TEXTURE = 15;
   */
  TYPE_TEXTURE = 15,

  /**
   * 계속 추가 예정...
   *
   * @generated from enum value: TYPE_STAMP = 16;
   */
  TYPE_STAMP = 16,
}
// Retrieve enum metadata with: proto3.getEnumType(ImageProcess_ProcessType)
proto3.util.setEnumType(ImageProcess_ProcessType, "retrica.ImageProcess.ProcessType", [
  { no: 0, name: "TYPE_NONE" },
  { no: 1, name: "TYPE_EXPOSURE" },
  { no: 2, name: "TYPE_BRIGHTNESS" },
  { no: 3, name: "TYPE_CONTRAST" },
  { no: 4, name: "TYPE_SHARPEN" },
  { no: 5, name: "TYPE_SATURATION" },
  { no: 6, name: "TYPE_HIGHLIGHTS" },
  { no: 7, name: "TYPE_SHADOWS" },
  { no: 8, name: "TYPE_WHITE_BALANCE" },
  { no: 9, name: "TYPE_VIGNETTE" },
  { no: 10, name: "TYPE_GRAIN" },
  { no: 11, name: "TYPE_LOOK_UP" },
  { no: 12, name: "TYPE_FADE" },
  { no: 13, name: "TYPE_BLUR" },
  { no: 14, name: "TYPE_HSL" },
  { no: 15, name: "TYPE_TEXTURE" },
  { no: 16, name: "TYPE_STAMP" },
]);

/**
 *
 * ImageProcess Params Definitions
 * Process type 이 추가되면 여기에 Process Params 정의를 추가하고,
 * ImageProcess message 의 params 에 새로 정의한 XXXParams 를 추가합니다.
 *
 * 01
 *
 * @generated from message retrica.ExposureParams
 */
export class ExposureParams extends Message<ExposureParams> {
  /**
   * @generated from field: int32 value = 1;
   */
  value = 0;

  constructor(data?: PartialMessage<ExposureParams>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.ExposureParams";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "value", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ExposureParams {
    return new ExposureParams().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ExposureParams {
    return new ExposureParams().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ExposureParams {
    return new ExposureParams().fromJsonString(jsonString, options);
  }

  static equals(a: ExposureParams | PlainMessage<ExposureParams> | undefined, b: ExposureParams | PlainMessage<ExposureParams> | undefined): boolean {
    return proto3.util.equals(ExposureParams, a, b);
  }
}

/**
 * 02
 *
 * @generated from message retrica.BrightnessParams
 */
export class BrightnessParams extends Message<BrightnessParams> {
  /**
   * @generated from field: int32 value = 1;
   */
  value = 0;

  constructor(data?: PartialMessage<BrightnessParams>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.BrightnessParams";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "value", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BrightnessParams {
    return new BrightnessParams().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BrightnessParams {
    return new BrightnessParams().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BrightnessParams {
    return new BrightnessParams().fromJsonString(jsonString, options);
  }

  static equals(a: BrightnessParams | PlainMessage<BrightnessParams> | undefined, b: BrightnessParams | PlainMessage<BrightnessParams> | undefined): boolean {
    return proto3.util.equals(BrightnessParams, a, b);
  }
}

/**
 * 03
 *
 * @generated from message retrica.ContrastParams
 */
export class ContrastParams extends Message<ContrastParams> {
  /**
   * @generated from field: int32 value = 1;
   */
  value = 0;

  constructor(data?: PartialMessage<ContrastParams>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.ContrastParams";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "value", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ContrastParams {
    return new ContrastParams().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ContrastParams {
    return new ContrastParams().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ContrastParams {
    return new ContrastParams().fromJsonString(jsonString, options);
  }

  static equals(a: ContrastParams | PlainMessage<ContrastParams> | undefined, b: ContrastParams | PlainMessage<ContrastParams> | undefined): boolean {
    return proto3.util.equals(ContrastParams, a, b);
  }
}

/**
 * 04
 *
 * @generated from message retrica.SharpenParams
 */
export class SharpenParams extends Message<SharpenParams> {
  /**
   * @generated from field: uint32 value = 1;
   */
  value = 0;

  constructor(data?: PartialMessage<SharpenParams>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.SharpenParams";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "value", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SharpenParams {
    return new SharpenParams().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SharpenParams {
    return new SharpenParams().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SharpenParams {
    return new SharpenParams().fromJsonString(jsonString, options);
  }

  static equals(a: SharpenParams | PlainMessage<SharpenParams> | undefined, b: SharpenParams | PlainMessage<SharpenParams> | undefined): boolean {
    return proto3.util.equals(SharpenParams, a, b);
  }
}

/**
 * 05
 *
 * @generated from message retrica.SaturationParams
 */
export class SaturationParams extends Message<SaturationParams> {
  /**
   * @generated from field: int32 value = 1;
   */
  value = 0;

  constructor(data?: PartialMessage<SaturationParams>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.SaturationParams";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "value", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SaturationParams {
    return new SaturationParams().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SaturationParams {
    return new SaturationParams().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SaturationParams {
    return new SaturationParams().fromJsonString(jsonString, options);
  }

  static equals(a: SaturationParams | PlainMessage<SaturationParams> | undefined, b: SaturationParams | PlainMessage<SaturationParams> | undefined): boolean {
    return proto3.util.equals(SaturationParams, a, b);
  }
}

/**
 * 06
 *
 * @generated from message retrica.HighlightsParams
 */
export class HighlightsParams extends Message<HighlightsParams> {
  /**
   * @generated from field: int32 value = 1;
   */
  value = 0;

  constructor(data?: PartialMessage<HighlightsParams>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.HighlightsParams";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "value", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): HighlightsParams {
    return new HighlightsParams().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): HighlightsParams {
    return new HighlightsParams().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): HighlightsParams {
    return new HighlightsParams().fromJsonString(jsonString, options);
  }

  static equals(a: HighlightsParams | PlainMessage<HighlightsParams> | undefined, b: HighlightsParams | PlainMessage<HighlightsParams> | undefined): boolean {
    return proto3.util.equals(HighlightsParams, a, b);
  }
}

/**
 * 07
 *
 * @generated from message retrica.ShadowsParams
 */
export class ShadowsParams extends Message<ShadowsParams> {
  /**
   * @generated from field: int32 value = 1;
   */
  value = 0;

  constructor(data?: PartialMessage<ShadowsParams>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.ShadowsParams";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "value", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ShadowsParams {
    return new ShadowsParams().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ShadowsParams {
    return new ShadowsParams().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ShadowsParams {
    return new ShadowsParams().fromJsonString(jsonString, options);
  }

  static equals(a: ShadowsParams | PlainMessage<ShadowsParams> | undefined, b: ShadowsParams | PlainMessage<ShadowsParams> | undefined): boolean {
    return proto3.util.equals(ShadowsParams, a, b);
  }
}

/**
 * 08
 *
 * @generated from message retrica.WhiteBalanceParams
 */
export class WhiteBalanceParams extends Message<WhiteBalanceParams> {
  /**
   * @generated from field: int32 temperature = 1;
   */
  temperature = 0;

  /**
   * @generated from field: int32 tint = 2;
   */
  tint = 0;

  constructor(data?: PartialMessage<WhiteBalanceParams>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.WhiteBalanceParams";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "temperature", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "tint", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): WhiteBalanceParams {
    return new WhiteBalanceParams().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): WhiteBalanceParams {
    return new WhiteBalanceParams().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): WhiteBalanceParams {
    return new WhiteBalanceParams().fromJsonString(jsonString, options);
  }

  static equals(a: WhiteBalanceParams | PlainMessage<WhiteBalanceParams> | undefined, b: WhiteBalanceParams | PlainMessage<WhiteBalanceParams> | undefined): boolean {
    return proto3.util.equals(WhiteBalanceParams, a, b);
  }
}

/**
 * 09
 *
 * @generated from message retrica.VignetteParams
 */
export class VignetteParams extends Message<VignetteParams> {
  /**
   * @generated from field: int32 amount = 1;
   */
  amount = 0;

  /**
   * @generated from field: uint32 midpoint = 2;
   */
  midpoint = 0;

  /**
   * @generated from field: int32 roundness = 3;
   */
  roundness = 0;

  /**
   * @generated from field: uint32 feather = 4;
   */
  feather = 0;

  constructor(data?: PartialMessage<VignetteParams>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.VignetteParams";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "amount", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "midpoint", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 3, name: "roundness", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "feather", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): VignetteParams {
    return new VignetteParams().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): VignetteParams {
    return new VignetteParams().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): VignetteParams {
    return new VignetteParams().fromJsonString(jsonString, options);
  }

  static equals(a: VignetteParams | PlainMessage<VignetteParams> | undefined, b: VignetteParams | PlainMessage<VignetteParams> | undefined): boolean {
    return proto3.util.equals(VignetteParams, a, b);
  }
}

/**
 * 10
 *
 * @generated from message retrica.GrainParams
 */
export class GrainParams extends Message<GrainParams> {
  /**
   * @generated from field: uint32 amount = 1;
   */
  amount = 0;

  /**
   * @generated from field: uint32 size = 2;
   */
  size = 0;

  /**
   * @generated from field: uint32 color = 3;
   */
  color = 0;

  constructor(data?: PartialMessage<GrainParams>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.GrainParams";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "amount", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "size", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 3, name: "color", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GrainParams {
    return new GrainParams().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GrainParams {
    return new GrainParams().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GrainParams {
    return new GrainParams().fromJsonString(jsonString, options);
  }

  static equals(a: GrainParams | PlainMessage<GrainParams> | undefined, b: GrainParams | PlainMessage<GrainParams> | undefined): boolean {
    return proto3.util.equals(GrainParams, a, b);
  }
}

/**
 * 11
 *
 * @generated from message retrica.LookUpParams
 */
export class LookUpParams extends Message<LookUpParams> {
  /**
   * @generated from field: string lut_url = 1;
   */
  lutUrl = "";

  /**
   * @generated from field: uint32 intensity = 2;
   */
  intensity = 0;

  constructor(data?: PartialMessage<LookUpParams>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.LookUpParams";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "lut_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "intensity", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LookUpParams {
    return new LookUpParams().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LookUpParams {
    return new LookUpParams().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LookUpParams {
    return new LookUpParams().fromJsonString(jsonString, options);
  }

  static equals(a: LookUpParams | PlainMessage<LookUpParams> | undefined, b: LookUpParams | PlainMessage<LookUpParams> | undefined): boolean {
    return proto3.util.equals(LookUpParams, a, b);
  }
}

/**
 * 12
 *
 * @generated from message retrica.FadeParams
 */
export class FadeParams extends Message<FadeParams> {
  /**
   * @generated from field: uint32 value = 1;
   */
  value = 0;

  constructor(data?: PartialMessage<FadeParams>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.FadeParams";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "value", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FadeParams {
    return new FadeParams().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FadeParams {
    return new FadeParams().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FadeParams {
    return new FadeParams().fromJsonString(jsonString, options);
  }

  static equals(a: FadeParams | PlainMessage<FadeParams> | undefined, b: FadeParams | PlainMessage<FadeParams> | undefined): boolean {
    return proto3.util.equals(FadeParams, a, b);
  }
}

/**
 * 13
 *
 * @generated from message retrica.BlurParams
 */
export class BlurParams extends Message<BlurParams> {
  /**
   * @generated from field: retrica.BlurParams.BlurType type = 1;
   */
  type = BlurParams_BlurType.TYPE_NONE;

  /**
   * @generated from field: uint32 amount = 2;
   */
  amount = 0;

  /**
   * @generated from field: uint32 degree = 3;
   */
  degree = 0;

  constructor(data?: PartialMessage<BlurParams>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.BlurParams";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "type", kind: "enum", T: proto3.getEnumType(BlurParams_BlurType) },
    { no: 2, name: "amount", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 3, name: "degree", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BlurParams {
    return new BlurParams().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BlurParams {
    return new BlurParams().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BlurParams {
    return new BlurParams().fromJsonString(jsonString, options);
  }

  static equals(a: BlurParams | PlainMessage<BlurParams> | undefined, b: BlurParams | PlainMessage<BlurParams> | undefined): boolean {
    return proto3.util.equals(BlurParams, a, b);
  }
}

/**
 * @generated from enum retrica.BlurParams.BlurType
 */
export enum BlurParams_BlurType {
  /**
   * @generated from enum value: TYPE_NONE = 0;
   */
  TYPE_NONE = 0,

  /**
   * @generated from enum value: TYPE_GAUSSIAN = 1;
   */
  TYPE_GAUSSIAN = 1,

  /**
   * @generated from enum value: TYPE_MOTION = 2;
   */
  TYPE_MOTION = 2,

  /**
   * @generated from enum value: TYPE_ZOOM = 3;
   */
  TYPE_ZOOM = 3,

  /**
   * @generated from enum value: TYPE_SPIN = 4;
   */
  TYPE_SPIN = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(BlurParams_BlurType)
proto3.util.setEnumType(BlurParams_BlurType, "retrica.BlurParams.BlurType", [
  { no: 0, name: "TYPE_NONE" },
  { no: 1, name: "TYPE_GAUSSIAN" },
  { no: 2, name: "TYPE_MOTION" },
  { no: 3, name: "TYPE_ZOOM" },
  { no: 4, name: "TYPE_SPIN" },
]);

/**
 * 14
 *
 * @generated from message retrica.HSLParams
 */
export class HSLParams extends Message<HSLParams> {
  /**
   * @generated from field: retrica.HSLParams.HSLColors hue = 1;
   */
  hue?: HSLParams_HSLColors;

  /**
   * @generated from field: retrica.HSLParams.HSLColors saturation = 2;
   */
  saturation?: HSLParams_HSLColors;

  /**
   * @generated from field: retrica.HSLParams.HSLColors lightness = 3;
   */
  lightness?: HSLParams_HSLColors;

  constructor(data?: PartialMessage<HSLParams>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.HSLParams";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "hue", kind: "message", T: HSLParams_HSLColors },
    { no: 2, name: "saturation", kind: "message", T: HSLParams_HSLColors },
    { no: 3, name: "lightness", kind: "message", T: HSLParams_HSLColors },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): HSLParams {
    return new HSLParams().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): HSLParams {
    return new HSLParams().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): HSLParams {
    return new HSLParams().fromJsonString(jsonString, options);
  }

  static equals(a: HSLParams | PlainMessage<HSLParams> | undefined, b: HSLParams | PlainMessage<HSLParams> | undefined): boolean {
    return proto3.util.equals(HSLParams, a, b);
  }
}

/**
 * @generated from message retrica.HSLParams.HSLColors
 */
export class HSLParams_HSLColors extends Message<HSLParams_HSLColors> {
  /**
   * @generated from field: int32 red = 1;
   */
  red = 0;

  /**
   * @generated from field: int32 yellow = 2;
   */
  yellow = 0;

  /**
   * @generated from field: int32 green = 3;
   */
  green = 0;

  /**
   * @generated from field: int32 cyan = 4;
   */
  cyan = 0;

  /**
   * @generated from field: int32 blue = 5;
   */
  blue = 0;

  /**
   * @generated from field: int32 magenta = 6;
   */
  magenta = 0;

  constructor(data?: PartialMessage<HSLParams_HSLColors>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.HSLParams.HSLColors";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "red", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "yellow", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "green", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "cyan", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "blue", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "magenta", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): HSLParams_HSLColors {
    return new HSLParams_HSLColors().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): HSLParams_HSLColors {
    return new HSLParams_HSLColors().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): HSLParams_HSLColors {
    return new HSLParams_HSLColors().fromJsonString(jsonString, options);
  }

  static equals(a: HSLParams_HSLColors | PlainMessage<HSLParams_HSLColors> | undefined, b: HSLParams_HSLColors | PlainMessage<HSLParams_HSLColors> | undefined): boolean {
    return proto3.util.equals(HSLParams_HSLColors, a, b);
  }
}

/**
 * 15
 *
 * @generated from message retrica.TextureParams
 */
export class TextureParams extends Message<TextureParams> {
  /**
   * @generated from field: string url = 1;
   */
  url = "";

  /**
   * @generated from field: retrica.BlendType blend_type = 2;
   */
  blendType = BlendType.ALPHA;

  /**
   * @generated from field: uint32 opacity = 3;
   */
  opacity = 0;

  constructor(data?: PartialMessage<TextureParams>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.TextureParams";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "blend_type", kind: "enum", T: proto3.getEnumType(BlendType) },
    { no: 3, name: "opacity", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TextureParams {
    return new TextureParams().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TextureParams {
    return new TextureParams().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TextureParams {
    return new TextureParams().fromJsonString(jsonString, options);
  }

  static equals(a: TextureParams | PlainMessage<TextureParams> | undefined, b: TextureParams | PlainMessage<TextureParams> | undefined): boolean {
    return proto3.util.equals(TextureParams, a, b);
  }
}

/**
 * 16
 *
 * @generated from message retrica.StampParams
 */
export class StampParams extends Message<StampParams> {
  /**
   * @generated from field: retrica.StampParams.StampType type = 1;
   */
  type = StampParams_StampType.TYPE_NONE;

  /**
   * @generated from field: string font_name = 2;
   */
  fontName = "";

  /**
   * @generated from field: uint32 font_size = 3;
   */
  fontSize = 0;

  /**
   * @generated from field: string color = 4;
   */
  color = "";

  /**
   * @generated from field: retrica.BlendType blend_type = 5;
   */
  blendType = BlendType.ALPHA;

  /**
   * @generated from field: uint32 opacity = 6;
   */
  opacity = 0;

  /**
   * @generated from field: retrica.Alignment alignment = 7;
   */
  alignment = Alignment.BOTTOM_RIGHT;

  /**
   * @generated from field: retrica.DateTimeFormat date_time_format = 8;
   */
  dateTimeFormat = DateTimeFormat.MEDIUM_DATE_TIME;

  /**
   * @generated from field: string text = 9;
   */
  text = "";

  /**
   * @generated from field: string url = 10;
   */
  url = "";

  constructor(data?: PartialMessage<StampParams>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.StampParams";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "type", kind: "enum", T: proto3.getEnumType(StampParams_StampType) },
    { no: 2, name: "font_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "font_size", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 4, name: "color", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "blend_type", kind: "enum", T: proto3.getEnumType(BlendType) },
    { no: 6, name: "opacity", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 7, name: "alignment", kind: "enum", T: proto3.getEnumType(Alignment) },
    { no: 8, name: "date_time_format", kind: "enum", T: proto3.getEnumType(DateTimeFormat) },
    { no: 9, name: "text", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StampParams {
    return new StampParams().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StampParams {
    return new StampParams().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StampParams {
    return new StampParams().fromJsonString(jsonString, options);
  }

  static equals(a: StampParams | PlainMessage<StampParams> | undefined, b: StampParams | PlainMessage<StampParams> | undefined): boolean {
    return proto3.util.equals(StampParams, a, b);
  }
}

/**
 * @generated from enum retrica.StampParams.StampType
 */
export enum StampParams_StampType {
  /**
   * @generated from enum value: TYPE_NONE = 0;
   */
  TYPE_NONE = 0,

  /**
   * @generated from enum value: TYPE_CAMERA_INFORMATION = 1;
   */
  TYPE_CAMERA_INFORMATION = 1,

  /**
   * @generated from enum value: TYPE_WEATHER = 2;
   */
  TYPE_WEATHER = 2,

  /**
   * @generated from enum value: TYPE_LOCATION = 3;
   */
  TYPE_LOCATION = 3,

  /**
   * @generated from enum value: TYPE_DATETIME = 4;
   */
  TYPE_DATETIME = 4,

  /**
   * @generated from enum value: TYPE_TEXT = 5;
   */
  TYPE_TEXT = 5,

  /**
   * @generated from enum value: TYPE_IMAGE = 6;
   */
  TYPE_IMAGE = 6,
}
// Retrieve enum metadata with: proto3.getEnumType(StampParams_StampType)
proto3.util.setEnumType(StampParams_StampType, "retrica.StampParams.StampType", [
  { no: 0, name: "TYPE_NONE" },
  { no: 1, name: "TYPE_CAMERA_INFORMATION" },
  { no: 2, name: "TYPE_WEATHER" },
  { no: 3, name: "TYPE_LOCATION" },
  { no: 4, name: "TYPE_DATETIME" },
  { no: 5, name: "TYPE_TEXT" },
  { no: 6, name: "TYPE_IMAGE" },
]);

